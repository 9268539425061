import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {
  public transform(value: string | number): string | number {
    return value !== undefined && value !== null ? parseInt(`${value}`, 10) : value;
  }
}
