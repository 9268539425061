import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import * as Redux from 'redux';
import { Observable, of as observableOf } from 'rxjs';

import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AppState } from '../../app.reducer';
import { AppStore } from '../../app.store';

import * as UserActions from '../../main/user/user.actions';
import { UserProfileModel } from '../model/user-profile.model';
import { UserService } from '../service/user.service';

export interface Credentials {
  token?: string;
  roles?: string;
}


@Injectable()
export class AuthenticationService {
  private _credentials: Credentials;
  private forceAuthorizationCode: string = !environment.production && environment['authorizationCode'] ?
    environment['authorizationCode'] : null;

  constructor(private http: HttpClient, private router: Router, @Inject(PLATFORM_ID) private platformId: Object,
              @Inject(AppStore) private store: Redux.Store<AppState>,
              private userService: UserService) {
    this.checkCredentials();
  }

  private checkCredentials(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('credentials') || localStorage.getItem('credentials')) {
        if (!this._credentials) {
          this._credentials = {token: null};
        }
        this._credentials.token = sessionStorage.getItem('credentials') || localStorage.getItem('credentials');
      }

      if (sessionStorage.getItem('roles') || localStorage.getItem('roles')) {
        if (!this._credentials) {
          this._credentials = {roles: null};
        }
        this._credentials.roles = sessionStorage.getItem('roles') || localStorage.getItem('roles');
      }
    }
  }

  public login(email: string, password: string, remember: boolean): any {
    const data: Credentials = {
      token: '',
      roles: ''
    };
    const apiUrl = `${environment.apiUrl}/login`;

    const request = {
      email: email,
      password: password
    };
    this.setCredentials();
    return this.http.post(apiUrl, request).pipe(map((response: Object) => {
      data.token = response['access_token'];
      this.setCredentials(data, remember);

      this.userService.getCurrentLoggedUser().subscribe((currentUser: UserProfileModel) => {
        data.roles = currentUser.user.roles.join(',');
        this.setCredentials(data, remember);
        this.store.dispatch(UserActions.setCurrentUser(currentUser));
      });
      return response;
    }));
  }

  public logout(): Observable<boolean> {
    this.setCredentials();
    this.router.navigate(['login']);
    return observableOf(true);
  }

  public canAccessRole(userAccessRole: string): boolean {
    if (this.credentials && this.credentials.roles) {
      const roleArray: Array<string> = this.credentials.roles.split(',');
      return _.some(roleArray, (role: string) => role === userAccessRole);
    }
  }

  public isAuthenticated(): boolean {
    return !!this.credentials && !!this.credentials.token || !!this.forceAuthorizationCode;
  }

  public forgotAuthenticationData(): void {
    this.setCredentials();
  }

  get credentials(): Credentials {
    return this._credentials;
  }

  private setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (!this._credentials) {
      this.store.dispatch(UserActions.setCurrentUser(null));
      if (this.credentials) {
        this.router.navigate(['login']);
      }
    }
    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem('credentials', credentials.token);
      storage.setItem('roles', credentials.roles);
    } else {
      sessionStorage.setItem('credentials', '');
      localStorage.setItem('roles', '');
    }
  }

}
