import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from './core/authentication/role.guard';
import { MainComponent } from './main/main.component';
import { ProjectComponent } from './main/project/project.component';
import { ActivateComponent } from './pre/activate/activate.component';
import { ForgotPasswordComponent } from './pre/forgot-password/forgot-password.component';
import { LoginComponent } from './pre/login/login.component';
import { RegisterComponent } from './pre/register/register.component';
import { ResetPasswordComponent } from './pre/reset-password/reset-password.component';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component';
import { UserRoleEnum } from './shared/enum/user-role.enum';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'headquarter',
    redirectTo: 'main/headquarter',
    pathMatch: 'full'
  },
  {
    path: 'main',
    component: MainComponent,
    children: [
      {
        path: 'projects',
        loadChildren: () => import('./main/project/project.module').then(module => module.ProjectModule)
      },
      {
        path: 'project-view/:id',
        component: ProjectComponent
      },
      {
        path: 'headquarter',
        canActivate: [RoleGuard],
        data: {
          accessRole: UserRoleEnum.admin
        },
        loadChildren: () => import('./main/admin/admin.module').then(module => module.AdminModule)
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent
  },
  {
    path: 'activate/:token',
    component: ActivateComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
