import { CompanyDtoModel } from './company.dto.model';
import { ProjectDtoModel } from './project.dto.model';

export class ProjectContractorDtoModel {
  public id: number;
  public createdDate: Date;
  public status: number;
  public company: any;
  public project: ProjectDtoModel;

  constructor(data?: any) {
    this.id = data && data.id || null;
    this.createdDate = data && data.createdDate ? new Date(data.createdDate) : null;
    this.status = data && data.status || null;
    this.project = data && data.project ? new ProjectDtoModel(data.project) : null;
    this.company = data && data.company ? new CompanyDtoModel(data.company) : null;
  }
}
