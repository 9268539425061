import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { SidebarItemGroupModel } from '../../../core/model/sidebar-item-group.model';
import { SidebarService } from './sidebar.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent {
  public sidebarItemGroups: Array<SidebarItemGroupModel>;
  public expandSidebar: boolean;
  public isBigScreen: boolean;
  public showSidebarToggle: boolean;

  @Output() public sidebarToggle: EventEmitter<boolean>;

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.checkScreenWidth(event.target.innerWidth);
  }

  constructor(private sidebarService: SidebarService) {
    this.sidebarToggle = new EventEmitter();
    this.sidebarItemGroups = [];
    this.checkScreenWidth(window.innerWidth);
    this.sidebarItemGroups = this.sidebarService.getSidebarItems;
  }

  private checkScreenWidth(width: number): void {
    this.showSidebarToggle = !!(width < 1440);
    this.isBigScreen = !!(width >= 1440);
  }

  public closeSidebar(): void {
    this.expandSidebar = !this.expandSidebar;
    this.sidebarToggle.emit(this.expandSidebar);
  }
}
