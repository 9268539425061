import * as _ from 'lodash';

import { CompanyDtoModel } from './company.dto.model';

import { ProjectProjectQuestionDtoModel } from './project-project-question.dto.model';
import { IndustryCategoryDtoModel } from './industry-category.dto.model';
import { ProjectContractorDtoModel } from './project-contractor.dto.model';

export class ProjectDtoModel {
  public id: number;
  public createdDate: Date;
  public stageOneFinishDate: Date;
  public stageTwoFinishDate: Date;
  public name: string;
  public description: string;
  public projectContractors: Array<ProjectContractorDtoModel>;
  public customer: CompanyDtoModel;
  public industryCategory: IndustryCategoryDtoModel;
  public projectProjectQuestions: Array<ProjectProjectQuestionDtoModel>;
  public status: number;
  public stage: number;
  public anonymous: boolean;

  constructor(data?: ProjectDtoModel) {
    this.id = data && data.id || null;
    this.createdDate = data && data.createdDate ? new Date(data.createdDate) : null;
    this.stageOneFinishDate = data && data.stageOneFinishDate ? new Date(data.stageOneFinishDate) : null;
    this.stageTwoFinishDate = data && data.stageTwoFinishDate ? new Date(data.stageTwoFinishDate) : null;
    this.name = data && data.name || null;
    this.description = data && data.description || null;
    this.projectContractors = data && data.projectContractors ? _.map(data.projectContractors, (contractor: ProjectContractorDtoModel) =>
      new ProjectContractorDtoModel(contractor)) : null;
    this.customer = data && data.customer ? new CompanyDtoModel(data.customer) : null;
    this.industryCategory = data && data.industryCategory ? new IndustryCategoryDtoModel(data.industryCategory) : null;
    this.projectProjectQuestions = data && data.projectProjectQuestions ?
      _.map(data.projectProjectQuestions, (projectQuestion) => new ProjectProjectQuestionDtoModel(projectQuestion)) : [];
    this.status = data && data.status || null;
    this.stage = data && data.stage || 0;
    this.anonymous = data && data.anonymous || null;
    this.industryCategory = data && data.industryCategory ? new IndustryCategoryDtoModel(data.industryCategory) : null;
  }
}
