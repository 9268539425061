import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringPrefix'
})
export class StringPrefixPipe implements PipeTransform {
  public transform(value: string | number, prefixKey: string): string | number {
    return prefixKey && value !== undefined && value !== null ? `${prefixKey}.${value}` : value;
  }
}
