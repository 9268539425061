import { Component, Input } from '@angular/core';
import { ProjectStageEnum } from '../../enum/project-stage.enum';

@Component({
  selector: 'app-project-stage-icon',
  templateUrl: './project-stage-icon.component.html',
  styleUrls: ['./project-stage-icon.component.sass']
})
export class ProjectStageIconComponent {
  public projectStageEnum = ProjectStageEnum;
  @Input() stage: ProjectStageEnum;
}
