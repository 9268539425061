<div class="search-input">
  <mat-form-field floatLabel="never">
    <mat-label>{{ 'forms.inputsLabel.search' | translate }}</mat-label>
    <input matInput type="search"
           [formControl]="searchInput"
           placeholder="">
    <div matPrefix [inlineSVG]="'search.svg'"></div>
    <button matSuffix mat-icon-button class="search-matSuffix"
            (click)="searchInput.setValue('')"
            *ngIf="searchInput.value">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</div>
