<div class="admin-table-container project-list-container">
  <div class="table-content">
    <table mat-table [dataSource]="listTable.dataSource" matSort *ngIf="!isLoading">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="column-id">{{ 'project.table.header.id' | translate }}</th>
        <td mat-cell class="column-id" [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even; let i=index; let row;">{{ row.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="column-name">{{ 'project.table.header.name' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even; let row" class="column-name">
          <div class="project-name">
            <span class="project-name-label">{{row.name}}</span>
            <div class="project-industry-category">
              <div class="project-industry-category-icon">
                <div [inlineSVG]="'project-category.svg'"></div>
              </div>
              <span class="project-industry-category-label"
                    [style.color]="row.industryCategory.color">{{row.industryCategory.name}}</span>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef
            class="column-customer-name">{{ 'project.table.header.customer' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even; let row"
            class="column-customer-name" (click)="goToProject(row)">
          <div class="project-customer-logo"></div>
          <span>{{ !row.anonymous ? row?.customer?.name : 'project.hidden' | translate }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="stage">
        <th mat-header-cell *matHeaderCellDef class="column-stage">{{ 'project.table.header.stage' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even; let row" class="column-stage">
          <app-project-stage-icon [stage]="row.stage"></app-project-stage-icon>
          <span>{{ "project.stage." + row.stage | translate}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="endStageTime">
        <th mat-header-cell *matHeaderCellDef
            class="column-end-stage-time">{{ 'project.table.header.endStageTime' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even; let row" class="column-end-stage-time">
          <div class="time-remaining"
               *ngIf="row?.stage === projectStageEnum.collectingContractors"
               matTooltip="{{ row?.stageOneFinishDate | date: 'dd/MM/yyyy HH:mm' }}">
            {{row?.stageOneFinishDate | amDifference: today :'days' : true | round }} dni
          </div>
          <div class="time-remaining"
               *ngIf="row?.stage === projectStageEnum.advanced"
               matTooltip="{{ row?.stageTwoFinishDate | date: 'dd/MM/yyyy HH:mm' }}">
            {{row?.stageTwoFinishDate | amDifference: today :'days' : true | round }} dni
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="listTable?.displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: listTable?.displayedColumns;" (click)="goToProject(row)"></tr>
    </table>

    <app-custom-spinner *ngIf="isLoading"></app-custom-spinner>

    <div class="no-data" *ngIf="!projects?.itemsCount && !isLoading">
      {{ 'common.noData' | translate }}
    </div>

    <mat-paginator *ngIf="projects?.pagesCount() > 1 && !isLoading"
                   [length]="projects?.itemsCount"
                   [pageSize]="projects?.limit"
                   [pageSizeOptions]="[10, 25, 50, 100]"
                   (page)="setPage($event)">
    </mat-paginator>
  </div>
</div>
