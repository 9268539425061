import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Redux from 'redux';
import { Unsubscribe } from 'redux';
import { AppState, getCurrentUser } from '../../app.reducer';
import { AppStore } from '../../app.store';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { UserProfileModel } from '../../core/model/user-profile.model';
import { ToasterService } from '../../shared/utils/toaster.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.sass'
  ]
})

export class LoginComponent implements OnDestroy {
  public form: FormGroup;
  public isLoading: boolean;
  public isRememberLogin: boolean;

  private storeUnsubscribe: Unsubscribe;

  constructor(@Inject(AppStore) private store: Redux.Store<AppState>,
              private authenticationService: AuthenticationService,
              private router: Router,
              private formBuilder: FormBuilder,
              private toasterService: ToasterService,
              private translateService: TranslateService) {
    this.isRememberLogin = false;
    this.isLoading = false;
    this.storeUnsubscribe = store.subscribe(() => this.updateState());
    this.updateState();
    this.createForm();
  }

  public login(): void {
    if (this.form.valid) {
      this.isLoading = true;
      this.authenticationService.login(
        this.form.controls['email'].value,
        this.form.controls['password'].value,
        this.form.controls['remember'].value)
        .subscribe(() => {
          this.isLoading = false;
        }, (err: HttpErrorResponse) => {
          if (err && err.error && err.error.message) {
            this.toasterService.showError([err.error.message]);
          } else {
            this.toasterService.showError([this.translateService.instant('common.toasters.unknownError')]);
          }
          this.isLoading = false;
        }, () => {
          this.isLoading = false;
        });
    } else {
      this.toasterService.showError(this.translateService.instant('forms.errors.formInvalid'));
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$')]],
      password: ['', Validators.required],
      remember: ['']
    });
  }

  private updateState(): void {
    const state = this.store.getState();
    const currentUser: UserProfileModel = getCurrentUser(state);

    if (currentUser) {
      this.router.navigate(['main']);
    }
  }

  public ngOnDestroy(): void {
    this.storeUnsubscribe();
  }

}
