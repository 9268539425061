import { Inject, Injectable } from '@angular/core';
import { CanActivate, Route, Router } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { getCurrentUser } from '../../main/user/users.reducer';
import { AppState } from '../../app.reducer';
import * as Redux from 'redux';
import { AppStore } from '../../app.store';
import { UserProfileModel } from '../model/user-profile.model';
import { MiscUtil } from '../../shared/utils/misc-util';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  currentUser: UserProfileModel;

  constructor(@Inject(AppStore) private store: Redux.Store<AppState>,
              private router: Router,
              private authenticationService: AuthenticationService) {
    this.updateState();
    store.subscribe(() => this.updateState());
  }

  updateState() {
    const state = this.store.getState();
    this.currentUser = getCurrentUser(state);
  }

  canActivate(): boolean {
    if (this.authenticationService.isAuthenticated()) {
      return true;
    }
    MiscUtil.goToPath('/login');
    return false;
  }

  canLoad(route: Route) {
  }

}
