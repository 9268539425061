import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, throwError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authentication = this.injector.get(AuthenticationService);

    let customReq = request.clone({
      headers: request.headers.set('Authorization', ``)
    });
    if (authentication.credentials && authentication.credentials.token) {
      customReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${authentication.credentials.token}`)
      });
    }
    return next.handle(customReq)
      .pipe(map((event: HttpEvent<any>) => event),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            authentication.forgotAuthenticationData();
          }
          return throwError(error);
        }));
  }
}
