import { Component, Input } from '@angular/core';
import { SidebarItemModel } from '../../../../core/model/sidebar-item.model';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.sass']
})
export class SidebarItemComponent {
  public expandSidebar: boolean;
  public sidebarItem: SidebarItemModel;

  @Input() set expandItem(value: boolean) {
    this.expandSidebar = value;
  }

  @Input() isBigScreen: boolean;

  @Input() set sidebarItemData(value: SidebarItemModel) {
    this.sidebarItem = value ? value : null;
  }

  constructor() {
  }
}
