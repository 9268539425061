import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PageEvent } from '@angular/material/typings/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { CompanyDtoService } from '../../../../core/service/company.dto.service';
import { DataPageRequest, DataPageRequestModel } from '../../../../shared/model/data-page-request.model';
import { DataPageModel } from '../../../../shared/model/data-page.model';
import { PopupHelper } from '../../../../shared/utils/popupHelper';
import { ToasterService } from '../../../../shared/utils/toaster.service';
import { CompanyDtoModel } from '../../../../core/model/company.dto.model';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html'
})
export class CompanyListComponent {
  public companyDataPage: DataPageModel<CompanyDtoModel>;
  public filtersForm: FormGroup;
  public isLoading: boolean;
  public rowOnHover: number;
  public rowProcessingId: number;
  public tableColumnsHeader: Array<string>;
  public tableDataSource: MatTableDataSource<CompanyDtoModel>;

  private companiesRequest: DataPageRequest;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private companyService: CompanyDtoService,
              private formBuilder: FormBuilder,
              private popupHelper: PopupHelper,
              private route: ActivatedRoute,
              private router: Router,
              private toasterService: ToasterService,
              private translateService: TranslateService) {
    this.companiesRequest = new DataPageRequestModel();
    this.tableColumnsHeader = ['id', 'name', 'address', 'city', 'contractor', 'customer', 'deleted', 'actions'];
    this.getCompanyList();
    this.createFilterForm();
  }

  public setPage($event: PageEvent): void {
    this.companiesRequest.page = $event.pageIndex + 1;
    this.companiesRequest.limit = $event.pageSize;
    this.getCompanyList();
  }

  public navigateToAddCompany(): void {
    this.router.navigate(['../create'], {relativeTo: this.route});
  }

  public navigateToEditCompany(companyId: number): void {
    this.router.navigate([`../edit/${companyId}`], {relativeTo: this.route});
  }

  public deleteCompany(companyId: number): void {
    this.rowProcessingId = companyId;
    this.popupHelper
      .showConfirm(
        `${this.translateService.instant('common.dialogs.confirmTitle')}?`,
        `${this.translateService.instant('common.dialogs.confirmDeleteItem')}?`)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.companyService.deleteCompanyAdmin(companyId)
            .pipe(finalize(() => {
              this.rowProcessingId = null;
            }))
            .subscribe(() => {
              this.getCompanyList();
            }, (error: HttpErrorResponse) => {
              if (error && error.error && error.error.message) {
                this.toasterService.showError([error.error.message]);
              } else {
                this.toasterService.showError([this.translateService.instant('common.toasters.unknownError')]);
              }
            });
        } else {
          this.rowProcessingId = null;
        }
      });
  }

  private getCompanyList(): void {
    this.isLoading = true;
    this.companyService.getCompanyDataPageAdmin(this.companiesRequest)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((data: DataPageModel<CompanyDtoModel>) => {
        if (data) {
          this.companyDataPage = data;
          this.setTableData(this.companyDataPage.items);
        }
      }, (error: HttpErrorResponse) => {
        if (error && error.error && error.error.message) {
          this.toasterService.showError([error.error.message]);
        } else {
          this.toasterService.showError([this.translateService.instant('common.toasters.unknownError')]);
        }
      });
  }

  private createFilterForm(): void {
    this.filtersForm = this.formBuilder.group({
      deleted: new FormControl(false),
      isCustomer: new FormControl(false),
      isContractor: new FormControl(false)
    });
  }

  private setTableData(companies: Array<CompanyDtoModel>): void {
    if (companies) {
      if (!this.tableDataSource) {
        this.tableDataSource = new MatTableDataSource<CompanyDtoModel>(companies);
      } else {
        this.tableDataSource.data = companies;
      }
    }
  }
}
