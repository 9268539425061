import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { IndustryCategoryDtoModel } from '../../../../core/model/industry-category.dto.model';
import { IndustryCategoryDtoService } from '../../../../core/service/industry-category.dto.service';
import { ToasterService } from '../../../../shared/utils/toaster.service';
import { IndustryCategoryFormComponent } from '../shared/industry-category-form/industry-category-form.component';

@Component({
  selector: 'app-industry-category-create',
  templateUrl: './industry-category-create.component.html'
})
export class IndustryCategoryCreateComponent {
  public isLoading: boolean;

  @ViewChild(IndustryCategoryFormComponent, {static: false}) industryCategoryFormComponent: any;

  constructor(private industryCategoryService: IndustryCategoryDtoService,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private route: ActivatedRoute,
              private router: Router) {
    this.isLoading = false;
  }

  public toolbarAction(actionEventLabel: string): void {
    switch (actionEventLabel) {
      case 'BACK':
        this.back();
        break;
      case 'SAVE':
        this.save();
        break;
    }
  }

  public back(): void {
    this.router.navigate(['../list'], {relativeTo: this.route});
  }

  public save(): void {
    const requestData: IndustryCategoryDtoModel = this.industryCategoryFormComponent.save();
    if (requestData) {
      this.isLoading = true;
      this.industryCategoryService.createIndustryCategoryAdmin(requestData)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((data: IndustryCategoryDtoModel) => {
          if (data) {
            this.toasterService.showSuccess([this.translateService.instant('common.toasters.successAdd')]);
            this.back();
          }
        }, (err: HttpErrorResponse) => {
          if (err && err.error && err.error.message) {
            this.toasterService.showError([err.error.message]);
          } else {
            this.toasterService.showError([this.translateService.instant('common.toasters.unknownError')]);
          }
        });
    }
  }
}
