import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SidebarItemGroupModel } from '../../../core/model/sidebar-item-group.model';

@Injectable()
export class SidebarService {
  private sidebarItems: Array<SidebarItemGroupModel>;

  constructor(private translateService: TranslateService) {
    this.setSidebarItems();
  }

  private setSidebarItems(): void {
    this.sidebarItems = [
      {
        label: this.translateService.instant('sidebar.itemsGroups.menu'),
        items: [
          {
            label: this.translateService.instant('sidebar.items.projectList'),
            icon: 'menu-projects.svg',
            url: './projects',
            notifyCount: 0
          },
          {
            label: this.translateService.instant('sidebar.items.projectMy'),
            icon: 'menu-my-projects.svg',
            url: './project-view',
            notifyCount: 0
          },
          {
            label: this.translateService.instant('sidebar.items.recipientsList'),
            icon: 'menu-recipients.svg',
            url: './a',
            notifyCount: 0
          },
          {
            label: this.translateService.instant('sidebar.items.educationalMaterials'),
            icon: 'menu-educational-materials.svg',
            url: './a',
            notifyCount: 0
          },
          {
            label: this.translateService.instant('sidebar.items.obtainingFinancing'),
            icon: 'menu-finances.svg',
            url: './a',
            notifyCount: 0
          },
          {
            label: this.translateService.instant('sidebar.items.contact'),
            icon: 'menu-contact.svg',
            url: './a',
            notifyCount: 0
          },
        ]
      },
      {
        label: this.translateService.instant('sidebar.itemsGroups.account'),
        items: [
          {
            label: this.translateService.instant('sidebar.items.settings'),
            icon: 'menu-settings.svg',
            url: './s',
            notifyCount: 0
          },
          {
            label: this.translateService.instant('sidebar.items.notifications'),
            icon: 'menu-notifications.svg',
            url: './d',
            notifyCount: 3
          },
          {
            label: this.translateService.instant('sidebar.items.chat'),
            icon: 'menu-chat.svg',
            url: './f',
            notifyCount: 0
          },
          {
            label: this.translateService.instant('sidebar.items.logout'),
            icon: 'menu-logout.svg',
            url: './f',
            notifyCount: 0
          }
        ]
      },
    ];
  }

  public get getSidebarItems(): Array<SidebarItemGroupModel> {
    return this.sidebarItems;
  }
}
