import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IAlertPopup } from '../popup/alert-popup/alert-popup.interface';
import { AlertPopupComponent } from '../popup/alert-popup/alert-popup.component';
import { IConfirmPopup } from '../popup/confirm-popup/confirm-popup.interface';
import { ConfirmPopupComponent } from '../popup/confirm-popup/confirm-popup.component';
import { MatDialogRef } from '@angular/material/dialog/typings/dialog-ref';

@Injectable()
export class PopupHelper {

  constructor(private snackBar: MatSnackBar,
              private dialog: MatDialog) {

  }

  showAlert(message): void {
    const alertPopupData: IAlertPopup = {
      title: 'Uwaga!',
      message: `${message}`
    };

    const dialogRef = this.dialog.open(AlertPopupComponent, {
      width: '440px',
      data: alertPopupData
    });

    dialogRef.afterClosed().subscribe();
  }

  showConfirm(title, message): MatDialogRef<ConfirmPopupComponent> {
    const confirmPopupData: IConfirmPopup = {
      title: title,
      message: `${message}`
    };

    return this.dialog.open(ConfirmPopupComponent, {
      width: '440px',
      data: confirmPopupData
    });
  }
}
