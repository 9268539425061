import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ProvniceDtoModel } from '../../../../../core/model/provnice.dto.model';
import { User } from '../../../../../core/model/user.model';
import { ProvinceServiceDto } from '../../../../../core/service/province.dto.service';
import { UserService } from '../../../../../core/service/user.service';
import { ToasterService } from '../../../../../shared/utils/toaster.service';
import { CompanyCreateDtoModel } from '../../../../../core/model/company-create.dto.model';
import { CompanyDtoModel } from '../../../../../core/model/company.dto.model';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.sass']
})
export class CompanyFormComponent implements OnDestroy {
  public isProcessingEmails: boolean;
  public isProcessingProvinces: boolean;
  public userData: User;
  public form: FormGroup;
  public provinces: Array<ProvniceDtoModel>;

  private formSubscription: Subscription;

  @Input() isEditMode: boolean;

  @Input() set editData(value: CompanyDtoModel) {
    if (value) {
      this.setFormInput(value);
    }
  }

  constructor(private commonService: ProvinceServiceDto,
              private formBuilder: FormBuilder,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private userService: UserService) {
    this.isProcessingEmails = false;
    this.isProcessingProvinces = false;
    this.provinces = [];
    this.createForm();
    this.getProvincesList();
  }

  public save(): CompanyCreateDtoModel {
    if (this.form.valid) {
      return new CompanyCreateDtoModel({
        name: this.form.controls['name'].value,
        street: this.form.controls['street'].value,
        streetNo: this.form.controls['streetNo'].value,
        province: this.form.controls['province'].value,
        city: this.form.controls['city'].value,
        isCustomer: this.form.controls['isCustomer'].value,
        isContractor: this.form.controls['isContractor'].value,
        userEmail: this.form.controls['userEmail'].value,
      });

    } else {
      this.toasterService.showError([this.translateService.instant('common.forms.errors.formInvalid')]);
      return null;
    }
  }

  public ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  private getProvincesList(): void {
    this.isProcessingProvinces = true;
    this.commonService.getProvincesList().subscribe((value: Array<ProvniceDtoModel>) => {
      this.provinces = value;
      this.isProcessingProvinces = false;
    });
  }

  private isEmailRegistered(inputValue: string): void {
    this.isProcessingEmails = true;
    this.userService.checkUserEmailAvailable(inputValue).subscribe((response: User) => {
      this.userData = response;
      this.isProcessingEmails = false;
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      street: ['', Validators.required],
      streetNo: ['', Validators.required],
      province: ['', Validators.required],
      city: ['', Validators.required],
      userEmail: [''],
      isContractor: [false],
      isCustomer: [false],
    });

    this.formSubscription = this.form.controls['userEmail'].valueChanges
      .pipe(debounceTime(700))
      .subscribe((value: string) => this.isEmailRegistered(value.trim()));
  }

  private setFormInput(companyData: CompanyDtoModel): void {
    this.form.controls['name'].setValue(companyData.name);
    this.form.controls['street'].setValue(companyData.street);
    this.form.controls['streetNo'].setValue(companyData.streetNo);
    this.form.controls['province'].setValue(companyData.province.id);
    this.form.controls['city'].setValue(companyData.city);
    this.form.controls['isContractor'].setValue(companyData.isContractor);
    this.form.controls['isCustomer'].setValue(companyData.isCustomer);
  }
}
