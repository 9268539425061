export interface DataPageRequest {
  limit: number;
  page: number;
  search?: string;
  sort?: string;
  order?: string;
}

export class DataPageRequestModel implements DataPageRequest {
  public limit: number;
  public page: number;
  public search?: string;
  public sort?: string;
  public order?: string;


  constructor(obj?: DataPageRequest) {
    this.page = obj && obj.page || 1;
    this.limit = obj && obj.limit || 50;
    this.search = obj && obj.search || undefined;
    this.sort = obj && obj.sort || undefined;
    this.order = obj && obj.order || undefined;
  }
}
