<div class="questions" *ngIf="project?.stage === projectStageEnum.collectingContractors">
  <div class="question" *ngFor="let question of project?.projectProjectQuestions">
    <div class="question-name">{{ question.projectQuestion.question }}</div>
    <div class="question-answer">{{ question.answer }}</div>
    <div class="question-files">
      <ul class="attachments">
        <li *ngFor="let file of question.files">
          <a [href]="getAttachmentUrl(file.fileName)" target="_blank">{{ file.label }}</a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="questions-nested" *ngIf="project?.stage === projectStageEnum.advanced">
  <mat-accordion [multi]="true">

    <mat-expansion-panel class="questions-collecting"  [expanded]="true" *ngFor="let questionCategory of questionGrouped | keyvalue">
      <mat-expansion-panel-header>
        {{ questionCategory.value[0].projectQuestion?.category?.name}}
      </mat-expansion-panel-header>

      <div class="questions-internal">
        <div class="question" *ngFor="let question of questionCategory.value">
          <div class="question-name">{{ question.projectQuestion.question }}</div>
          <div class="question-answer">{{ question.answer }}</div>
          <div class="question-files">
            <ul class="attachments">
              <li *ngFor="let file of question.files">
                <a [href]="getAttachmentUrl(file.fileName)" target="_blank">{{ file.label }}</a>
              </li>
            </ul>
          </div>
          <ul class="questions-child">
            <li *ngFor="let questionSub of question.children">
              <div class="question">
                <div class="question-name">{{ questionSub.projectQuestion.question }}</div>
                <div class="question-answer">{{ questionSub.answer }}</div>
                <div class="question-files">
                  <ul class="attachments">
                    <li *ngFor="let file of questionSub.files">
                      <a [href]="getAttachmentUrl(file.fileName)" target="_blank">{{ file.label }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </mat-expansion-panel>

    <mat-expansion-panel class="questions-collecting"  [expanded]="true">
      <mat-expansion-panel-header>
        Pytania i odpowiedzi
      </mat-expansion-panel-header>

      <div class="questions-internal">
        <div class="question">
<!--          <div class="question-name">{{ question.projectQuestion.question }}</div>-->
<!--          <div class="question-answer">{{ question.answer }}</div>-->
<!--          <div class="question-files">-->
<!--            <ul class="attachments">-->
<!--              <li *ngFor="let file of question.files">-->
<!--                <a [href]="getAttachmentUrl(file.fileName)" target="_blank">{{ file.label }}</a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
          <ul class="questions-child">
            <li *ngFor="let questionSub of userQuestions">
              <div class="question">
                <div class="question-name">{{ questionSub.question }}</div>
                <div class="question-answer">{{ questionSub.answer }}</div>
              </div>
            </li>
          </ul>
        </div>

        <div class="send-question">
          <div class="question-field">
            <input placeholder="Zadaj pytanie" [formControl]="questionNewControl" (keyup.enter)="sendQuestion()"/>
          </div>
          <div class="success" *ngIf="showSuccess">Twoje pytanie zostało przesłanie.</div>
        </div>
      </div>

    </mat-expansion-panel>
  </mat-accordion>



<!--  <div class="questions">-->
<!--    <div class="question" *ngFor="let question of questionGrouped">-->
<!--      <div class="question-name">{{ question.projectQuestion.question }}</div>-->
<!--      <div class="question-answer">{{ question.answer }}</div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <h2>Proces</h2>-->
<!--  <div class="questions">-->
<!--    <div class="question" *ngFor="let question of questionGrouped[projectQuestionCategoryEnum.process]">-->
<!--      <div class="question-name">{{ question.projectQuestion.question }}</div>-->
<!--      <div class="question-answer">{{ question.answer }}</div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <h2>Przezbrojenia</h2>-->
<!--  <div class="questions">-->
<!--    <div class="question" *ngFor="let question of questionGrouped[projectQuestionCategoryEnum.changeover]">-->
<!--      <div class="question-name">{{ question.projectQuestion.question }}</div>-->
<!--      <div class="question-answer">{{ question.answer }}</div>-->
<!--    </div>-->
<!--  </div>-->
</div>
