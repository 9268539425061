import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataPageRequest } from '../../shared/model/data-page-request.model';
import { DataPage, DataPageModel } from '../../shared/model/data-page.model';
import { objectToParams } from '../../shared/utils/object-to-params';
import { CompanyCreateDtoModel } from '../model/company-create.dto.model';
import { IndustryCategoryDtoModel } from '../model/industry-category.dto.model';
import { CompanyDtoModel } from '../model/company.dto.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyDtoService {

  constructor(private http: HttpClient) {
  }

  public getCompanyDataPageAdmin(request: DataPageRequest): Observable<DataPage<CompanyDtoModel>> {
    const filters = objectToParams(request);

    return this.http.get(`${environment.apiUrl}/admin/companies?${filters}`)
      .pipe(
        map((response: DataPage<IndustryCategoryDtoModel>) => (
          new DataPageModel<CompanyDtoModel>(response) as DataPageModel<CompanyDtoModel>)));
  }

  public getCompanyByIdAdmin(companyId: number): Observable<CompanyDtoModel> {
    return this.http.get(`${environment.apiUrl}/admin/companies/${companyId}`)
      .pipe(
        map((response: CompanyDtoModel) => response as CompanyDtoModel));
  }

  public createCompanyAdmin(request: CompanyCreateDtoModel): Observable<CompanyDtoModel> {
    return this.http.post(`${environment.apiUrl}/admin/companies`, request)
      .pipe(
        map((response: CompanyDtoModel) => response as CompanyDtoModel));
  }

  public updateCompanyAdmin(companyId: number, request: CompanyCreateDtoModel): Observable<CompanyDtoModel> {
    return this.http.put(`${environment.apiUrl}/admin/companies/${companyId}`, request)
      .pipe
      (map((response: CompanyDtoModel) => response as CompanyDtoModel));
  }

  public deleteCompanyAdmin(companyId: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/admin/companies/${companyId}`)
      .pipe(
        map((response: any) => response));
  }
}
