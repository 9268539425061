import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'booleanTranslate'
})
export class BooleanTranslatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }


  transform(value: any, args?: any): any {

    if (value) {
      return this.translateService.instant('common.yes');
    }
    return this.translateService.instant('common.no');
  }

}
