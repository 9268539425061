import { Component } from '@angular/core';
import { AdminNavbarItemModel } from '../../core/model/admin-navbar-item.model';
import { NavbarService } from '../../shared/component/navbar/navbar.service';
import { AdminService } from './admin.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.sass']
})
export class AdminComponent {
  public adminNavbarLinks: Array<AdminNavbarItemModel>;

  constructor(private adminService: AdminService,
              private navbarService: NavbarService) {
    this.navbarService.setNavbarTitle('admin.title');
    this.adminNavbarLinks = this.adminService.adminNavbarItems;
  }
}
