import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataPageRequest } from '../../shared/model/data-page-request.model';
import { DataPageModel } from '../../shared/model/data-page.model';
import { objectToParams } from '../../shared/utils/object-to-params';
import { CompanyCreateDtoModel } from '../model/company-create.dto.model';
import { CompanyDtoModel } from '../model/company.dto.model';
import { ProjectDtoModel } from '../model/project.dto.model';

@Injectable()
export class ProjectDtoService {
  constructor(private http: HttpClient) {

  }

  public getList(paramRequest?: DataPageRequest): Observable<DataPageModel<ProjectDtoModel>> {
    return this.http.get(`${environment.apiUrl}/projects?${objectToParams(paramRequest)}`)
      .pipe(map((response: DataPageModel<ProjectDtoModel>) => {
      const dataPage: DataPageModel<ProjectDtoModel> = new DataPageModel<ProjectDtoModel>(response);
      dataPage.items = _.map(dataPage.items, (item: ProjectDtoModel) => new ProjectDtoModel(item));
      return dataPage;
    }));
  }

  public getById(id: number): Observable<ProjectDtoModel> {
    return this.http.get(`${environment.apiUrl}/projects/${id}`)
      .pipe(map((response: ProjectDtoModel) => new ProjectDtoModel(response)));
  }

  public assign(projectId: number): Observable<ProjectDtoModel> {
    return this.http.post(`${environment.apiUrl}/projects/${projectId}/assign`, {})
      .pipe(
        map((response: CompanyDtoModel) => response as ProjectDtoModel));
  }
}
