import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { CompanyCreateComponent } from './company/company-create/company-create.component';
import { CompanyEditComponent } from './company/company-edit/company-edit.component';
import { CompanyListComponent } from './company/company-list/company-list.component';
import { CompanyComponent } from './company/company.component';
import { IndustryCategoryCreateComponent } from './industry-category/industry-category-create/industry-category-create.component';
import { IndustryCategoryEditComponent } from './industry-category/industry-category-edit/industry-category-edit.component';
import { IndustryCategoryListComponent } from './industry-category/industry-category-list/industry-category-list.component';
import { IndustryCategoryComponent } from './industry-category/industry-category.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'industry-category',
        pathMatch: 'full'
      },
      {
        path: 'industry-category',
        component: IndustryCategoryComponent,
        children: [
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
          {
            path: 'list',
            component: IndustryCategoryListComponent
          },
          {
            path: 'create',
            component: IndustryCategoryCreateComponent
          },
          {
            path: 'edit/:id',
            component: IndustryCategoryEditComponent
          }
        ]
      },
      {
        path: 'company',
        component: CompanyComponent,
        children: [
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
          {
            path: 'list',
            component: CompanyListComponent
          },
          {
            path: 'create',
            component: CompanyCreateComponent
          },
          {
            path: 'edit/:id',
            component: CompanyEditComponent
          }
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
