import { Injectable } from '@angular/core';

export interface IUser {
  id: number;
  avatar: string;
  fullName: string;
  username: string;
  firstName: string;
  lastName: string;
  publicname: string;
  email: string;
  phone: string;
  type: string;
  enabled: boolean;
  created: Date;
  roles: Array<string>;
}

@Injectable()
export class User implements IUser {
  public id: number;
  public avatar: string;
  public fullName: string;
  public username: string;
  public publicname: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public type: string;
  public enabled: boolean;
  public created: Date;
  public roles: Array<string>;

  constructor(obj?: any) {
    this.id = obj && obj.id || null;
    this.avatar = obj && obj.avatar || null;
    this.fullName = obj && obj.fullName || null;
    this.username = obj && obj.username || null;
    this.publicname = obj && obj.publicname || null;
    this.firstName = obj && obj.firstName || null;
    this.lastName = obj && obj.lastName || null;
    this.email = obj && obj.email || null;
    this.phone = obj && obj.phone || null;
    this.type = obj && obj.type || null;
    this.enabled = obj && obj.enabled || null;
    this.created = obj && obj.created || null;
    this.roles = obj && obj.roles || [];
  }
}
