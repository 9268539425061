import { Component } from '@angular/core';
import { PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { DataPageModel } from '../../../../../shared/model/data-page.model';
import { ProjectStageEnum } from '../../../../../shared/enum/project-stage.enum';
import { ProjectDtoModel } from '../../../../../core/model/project.dto.model';
import { DataPageRequestModel } from '../../../../../shared/model/data-page-request.model';
import { CompanyDtoModel } from '../../../../../core/model/company.dto.model';
import { ProjectDtoService } from '../../../../../core/service/project.dto.service';
import { DatabaseTable } from '../../../../../shared/model/database-table.model';
import { DatabaseTableDataSource } from '../../../../../shared/model/database-table-data-source.model';


@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.sass']
})
export class ProjectListComponent {
  public isLoading: boolean;
  public listTable: any;
  public projects: DataPageModel<ProjectDtoModel>;
  public projectStageEnum = ProjectStageEnum;
  public today: Date;

  private projectListRequest: DataPageRequestModel;

  constructor(private projectDtoService: ProjectDtoService,
              private router: Router) {
    this.today = new Date();
    this.listTable = {
      displayedColumns: ['id', 'name', 'customerName', 'stage', 'endStageTime'],
      databaseTable: new DatabaseTable<ProjectDtoModel>(),
      dataSource: null
    };

    this.listTable.dataSource = new DatabaseTableDataSource(this.listTable.databaseTable);
    this.projectListRequest = new DataPageRequestModel();
    this.getProjects();
  }

  public setPage($event: PageEvent): void {
    this.projectListRequest.page = $event.pageIndex + 1;
    this.projectListRequest.limit = $event.pageSize;
    this.getProjects();
  }

  public goToProject(row: ProjectDtoModel): void {
    this.router.navigate([`main/projects/${row.id}`]);
  }

  private getProjects(): void {
    this.isLoading = true;
    this.projectDtoService.getList(this.projectListRequest).subscribe((data: DataPageModel<ProjectDtoModel>) => {
      if (data) {
        this.projects = data;
        this.listTable.databaseTable.dataChange.next(data.items);
      }
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }
}
