import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Redux from 'redux';
import { Unsubscribe } from 'redux';
import * as _ from 'lodash';
import { AppState, getCurrentUser } from '../../../app.reducer';
import { AppStore } from '../../../app.store';
import { ProjectDtoModel } from '../../../core/model/project.dto.model';
import { UserProfileModel } from '../../../core/model/user-profile.model';
import { ProjectDtoService } from '../../../core/service/project.dto.service';
import { ProjectStageEnum } from '../../../shared/enum/project-stage.enum';
import { ProjectQuestionCreatePopupComponent } from './project-question-create-popup/project-question-create-popup.component';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.sass']
})
export class ProjectViewComponent implements OnInit {
  public projectData: ProjectDtoModel;
  public today: Date;
  public projectStageEnum = ProjectStageEnum;
  public currentUser: UserProfileModel;
  public get avatarBgCssStyleValue(): string {
    if (this.projectData && this.projectData.customer.avatar) {
      return `url(${this.projectData.customer.avatar})`;
    }
    return 'none';
  }
  public get customerLabel(): string {
    return this.projectData && this.projectData.anonymous ? this.anonymousLabel : this.projectData ? this.projectData.customer.name : '';
  }
  public get remainTimeLabel(): string {
    return ``;
  }

  private projectId: number;
  private unsubscribe: Unsubscribe;
  private anonymousLabel: string;

  constructor(@Inject(AppStore) private store: Redux.Store<AppState>,
              private dialog: MatDialog,
              private projectDtoService: ProjectDtoService,
              private route: ActivatedRoute,
              private translateService: TranslateService) {
    this.today = new Date();
    this.anonymousLabel = this.translateService.instant('common.anonymous');
    this.route.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
    });
    this.unsubscribe = store.subscribe(() => this.updateState());
    this.updateState();
    this.getProject(this.projectId);

    this.translateService.onLangChange.subscribe(() => {
      this.anonymousLabel = this.translateService.instant('common.anonymous');
    });
  }

  ngOnInit() {
  }

  updateState() {
    const state = this.store.getState();
    const currentUser: UserProfileModel = getCurrentUser(state);
    if (!_.isEqual(this.currentUser, currentUser)) {
      this.currentUser = getCurrentUser(state);
    }
  }

  public getProject(id: number) {
    this.projectDtoService.getById(id).subscribe((project: ProjectDtoModel) => {
      this.projectData = project;
    });
  }

  public showQuestionCreatePopup(): void {
    const refDialog = this.dialog.open(ProjectQuestionCreatePopupComponent, {
      width: '700px',
      data: {
        projectId: this.projectData.id
      },
      panelClass: 'my-popup',
      backdropClass: 'my-backdrop-popup',
      disableClose: true
    });

    refDialog.afterClosed().subscribe((data: any) => {

    });
  }

  public join() {
    this.projectDtoService.assign(this.projectData.id).subscribe(() => {
      this.getProject(this.projectData.id);
    });
  }
}
