import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import * as Redux from 'redux';
import * as _ from 'lodash';

import { filter } from 'rxjs/operators';
import { AppStore } from '../app.store';
import { getCurrentUser } from './user/users.reducer';
import { DOCUMENT } from '@angular/common';
import { UserProfileModel } from '../core/model/user-profile.model';
import { Subscription } from 'rxjs';
import { AppState } from '../app.reducer';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MainComponent implements OnDestroy {
  public isSidebarOpen: boolean;
  public currentUser: UserProfileModel;
  public isShowBlur: boolean;
  private sub: Subscription;
  mobileQuery: MediaQueryList;
  isPC: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(@Inject(AppStore) private store: Redux.Store<AppState>,
              @Inject(DOCUMENT) private document: Document,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private dialog: MatDialog,
              private router: Router) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.isPC = media.matchMedia('(min-width: 1200px)');

    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    store.subscribe(() => this.updateState());
    this.updateState();

    this.sub = router.events.pipe(
      filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event['url'] === '/main') {
          this.router.navigate(['main/projects/list']);
        }
      });

    this.dialog.afterOpened.subscribe(() => {
      this.isShowBlur = true;
    });
    this.dialog.afterAllClosed.subscribe(() => {
      this.isShowBlur = false;
    });
  }

  public toggleSidebar(event: boolean): void {
    this.isSidebarOpen = event;
  }

  private updateState() {
    const state = this.store.getState();
    const currentUser: UserProfileModel = getCurrentUser(state);

    if (this.isCanUpdateLayout(currentUser)) {
      this.currentUser = getCurrentUser(state);
    }
  }

  public ngOnDestroy(): void {
    if (this.sub && this.sub.unsubscribe) {
      this.sub.unsubscribe();
    }
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  private isCanUpdateLayout(user: UserProfileModel): boolean {
    return (!_.isEqual({...this.currentUser}, {...user}));
  }
}
