<form [formGroup]="form">
  <div>
    <mat-form-field appearance="outline" class="name-field">
      <mat-label>{{ 'admin.company.form.labels.name' | translate }}</mat-label>
      <input [formControl]="form.controls['name']" matInput type="text" placeholder="">
      <mat-error>
        <div *ngIf="form.controls['name'].hasError('required')">{{ 'common.forms.errors.required' | translate }}</div>
      </mat-error>
    </mat-form-field>
    <div class="form-checkbox-container">
      <mat-checkbox [formControl]="form.controls['isCustomer']">{{ 'admin.company.form.labels.isCustomer' | translate }}</mat-checkbox>
      <mat-checkbox [formControl]="form.controls['isContractor']">{{ 'admin.company.form.labels.isContractor' | translate }}</mat-checkbox>
    </div>
  </div>

  <div>
    <mat-form-field appearance="outline" class="name-field">
      <mat-label>{{ 'admin.company.form.labels.street' | translate }}</mat-label>
      <input [formControl]="form.controls['street']" matInput type="text" placeholder="">
      <mat-error>
        <div *ngIf="form.controls['street'].hasError('required')">{{ 'common.forms.errors.required' | translate }}</div>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="name-field">
      <mat-label>{{ 'admin.company.form.labels.streetNo' | translate }}</mat-label>
      <input [formControl]="form.controls['streetNo']" matInput type="text" placeholder="">
      <mat-error>
        <div *ngIf="form.controls['streetNo'].hasError('required')">{{ 'common.forms.errors.required' | translate }}</div>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="name-field">
      <mat-label>{{ 'admin.company.form.labels.city' | translate }}</mat-label>
      <input [formControl]="form.controls['city']" matInput type="text" placeholder="">
      <mat-error>
        <div *ngIf="form.controls['city'].hasError('required')">{{ 'common.forms.errors.required' | translate }}</div>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="name-field">
      <mat-label>{{ 'admin.company.form.labels.province' | translate }}</mat-label>
      <mat-select [formControl]="form.controls['province']">
        <mat-option *ngFor="let province of provinces" [value]="province.id">{{ province.name }}</mat-option>
      </mat-select>
      <mat-spinner class="input-suffix-spinner" matSuffix *ngIf="isProcessingProvinces"></mat-spinner>
      <mat-error>
        <div *ngIf="form.controls['province'].hasError('required')">{{ 'common.forms.errors.required' | translate }}</div>
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="name-field" *ngIf="!isEditMode">
      <mat-label>{{ 'admin.company.form.labels.userEmail' | translate }}</mat-label>
      <input [formControl]="form.controls['userEmail']" matInput type="text" placeholder="" [required]="!isEditMode">
      <mat-spinner class="input-suffix-spinner" matSuffix *ngIf="isProcessingEmails"></mat-spinner>
      <mat-error>
        <div *ngIf="form.controls['userEmail'].hasError('required')">{{ 'common.forms.errors.required' | translate }}</div>
      </mat-error>
      <mat-hint>
        <div
          *ngIf="!form.controls['userEmail'].pristine && !userData">{{ 'admin.company.create.newUserInfo' | translate }}</div>
        <div *ngIf="userData">{{ userData.publicname }}</div>
      </mat-hint>
    </mat-form-field>
  </div>

</form>
