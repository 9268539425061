import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../shared/utils/toaster.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AssetsService } from '../../shared/utils/assets.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../core/service/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnDestroy {
  public sub: Subscription;
  public loading: boolean;
  public registerForm: FormGroup;
  public errorMessage: string;
  public successMessage: string;

  private token: string;

  private static areEqual(formGroup) {
    if (formGroup.controls['second'].value &&
      formGroup.controls['first'].value !== formGroup.controls['second'].value) {
      return {
        areEqual: true
      };
    } else if (formGroup.controls['first'].value === formGroup.controls['second'].value) {
      return null;
    }
  }

  constructor(private assetsService: AssetsService,
              private dialog: MatDialog,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private toasterService: ToasterService,
              private router: Router,
              private route: ActivatedRoute) {
    this.createForm();
    this.sub = this.route.params.subscribe(params => {
      const token = atob(params['token']);
      const req = JSON.parse(token);
      this.token = req['token'];
    });

    this.loading = false;
  }

  public changePassword(): void {
    if (this.registerForm.valid && this.token) {
      this.loading = true;
      const request = {
        token: this.token,
        passwords: {
          first: this.registerForm.controls['passwords']['controls']['first'].value,
          second: this.registerForm.controls['passwords']['controls']['second'].value
        }
      };
      this.errorMessage = null;
      this.userService.resetPassword(request).subscribe((result: {[s: string]: string}) => {
        this.loading = false;
        this.successMessage = result.message;
      }, (err: HttpErrorResponse) => {
        this.toasterService.showError(err.error.errors);
        this.loading = false;
        this.errorMessage = err.error.error;
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.sub && this.sub.unsubscribe) {
      this.sub.unsubscribe();
    }
  }

  private createForm(): void {
    this.registerForm = this.formBuilder.group({
      'passwords': this.formBuilder.group({
        first: ['', Validators.required],
        second: ['', Validators.required]
      }, {validator: ResetPasswordComponent.areEqual})
    });
  }
}
