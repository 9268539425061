<div class="login-card">
  <div class="login-card-header">
    <div class="login-card-header-logo">
      <div class="login-logo" [inlineSVG]="'logo-dpx-color.svg'"></div>
    </div>
    <div class="login-card-header-title">{{ 'login.loginTitle' | translate }}</div>
  </div>
  <div class="login-card-content">
    <form [formGroup]="form">
      <div class="my-input-field">
        <mat-form-field floatLabel="never" hideRequiredMarker>
          <mat-label>{{ 'forms.inputsLabel.email' | translate }}</mat-label>
          <input matInput type="email" placeholder=""
                 [formControl]="form.controls['email']">
          <div class="input-prefix-icon" matPrefix [inlineSVG]="'email.svg'"></div>
          <mat-error>
            <div *ngIf="form.controls['email'].hasError('required')">
              {{ 'forms.errors.required' | translate }}
            </div>
            <div *ngIf="form.controls['email'].hasError('pattern')">
              {{ 'forms.errors.emailPattern' | translate }}
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="my-input-field">
        <mat-form-field floatLabel="never" hideRequiredMarker>
          <mat-label>{{ 'forms.inputsLabel.password' | translate }}</mat-label>
          <input matInput type="password" placeholder=""
                 [formControl]="form.controls['password']">
          <div class="input-prefix-icon" matPrefix [inlineSVG]="'password.svg'"></div>

          <mat-error>
            <div *ngIf="form.controls['password'].hasError('required')">
              {{ 'forms.errors.required' | translate }}
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="login-form-options">
        <mat-radio-group [formControl]="form.controls['remember']">
          <mat-radio-button class="login-radio-btn" [checked]="isRememberLogin"
                            (click)="isRememberLogin = !isRememberLogin">{{ 'login.rememberLogin' | translate }}</mat-radio-button>
        </mat-radio-group>
        <a [routerLink]="['/forgot-password']">{{ 'login.forgotPassword.buttonLabel' | translate }}</a>
      </div>

      <div class="login-card-buttons-container">
        <button class="my-custom-button" mat-button
                (click)="login()" [disabled]="isLoading">{{ (isLoading ? 'login.logInProcessingButtonLabel' :'login.logInButtonLabel')| translate }}
        </button>
        <!--<a [routerLink]="['/register']">{{'login.signUpButtonLabel'| translate}}</a>-->
      </div>

    </form>
  </div>
</div>
