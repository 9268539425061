<div class="login-card mat-elevation-z4">
   <mat-progress-bar mode="indeterminate" class="loading-bar" *ngIf="loading"></mat-progress-bar>
   <div class="login-card-header">
      <div class="login-card-header-title">Aktywacja konta</div>
   </div>
   <div class="login-card-content">
      <div class="login-content" *ngIf="loading">
         <div>
            <p>Aktywowanie konta.</p>
         </div>
      </div>
      <div class="login-content" *ngIf="success">
         <div>
            <p>Konto zostało aktywowane, możesz się zalogować.</p>
         </div>
         <div class="button">
            <button color="primary" class="ed-button ed-button-big mat-flat-button mat-raised-button"
                    mat-raised-button (click)="goToLogin()">
               Logowanie
            </button>
         </div>
      </div>

      <div class="login-content" *ngIf="error">
         <div>
            <p>Wprowadzony token jest niepoprawny</p>
         </div>
         <div class="button">
            <button color="primary" class="ed-button ed-button-big mat-flat-button mat-raised-button"
                    mat-raised-button (click)="goToForgotPassword()">
               Przypomnij hasło
            </button>
         </div>
      </div>

      <div class="login-content">
         <div class="note"><a [routerLink]="['/register']">Załóż konto</a> lub <a [routerLink]="['/login']">Zaloguj się</a></div>
      </div>
   </div>
</div>
