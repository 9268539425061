<div class="admin-table-container">
  <div class="table-toolbar">
    <div class="table-toolbar-filters">
      <div
        class="table-toolbar-filters-label"> {{ 'admin.industryCategory.table.filters.label' | translate }}</div>
      <mat-checkbox class="table-toolbar-filters-checkbox" disableRipple>
        {{ 'admin.industryCategory.table.filters.showDeletedCheckbox' | translate }}
      </mat-checkbox>
    </div>
    <div>
      <button (click)="navigateToAddIndustryCategory()" mat-button class="my-custom-button">
        {{ 'common.buttons.add' | translate }}
      </button>
    </div>
  </div>

  <div class="table-content">
    <table mat-table [dataSource]="tableDataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> {{ 'admin.industryCategory.table.header.id' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even; let row">{{row.id}}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{ 'admin.industryCategory.table.header.name' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even; let row">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef> {{ 'admin.industryCategory.table.header.color' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'" *matCellDef="let even = even; let row">
          <div [style.backgroundColor]="row && row.color ? row.color :''" class="industry-category-color"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="admin-table-action-column"></th>
        <td class="admin-table-action-column" mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even; let row;" [class.show-buttons]="rowOnHover === row.id">
          <button *ngIf="rowProcessingId !== row.id"
                  mat-icon-button
                  (click)="navigateToEditIndustryCategory(row.id)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="rowProcessingId !== row.id"
                  mat-icon-button
                  (click)="deleteIndustryCategory(row.id)">
            <mat-icon>delete</mat-icon>
          </button>
          <div class="table-action-spinner" *ngIf="rowProcessingId === row.id">
            <mat-spinner></mat-spinner>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumnsHeader; sticky: true"></tr>
      <tr [class.disable-row]="rowProcessingId === row.id"
          (mouseenter)="rowOnHover = row.id"
          (mouseleave)="rowOnHover = null"
          mat-row
          *matRowDef="let row; columns: tableColumnsHeader;"></tr>
    </table>

    <app-custom-spinner *ngIf="isLoading"></app-custom-spinner>

    <div class="no-data" *ngIf="!industryCategoryDataPage?.itemsCount && !isLoading">
      {{ 'common.noData' | translate }}
    </div>

    <mat-paginator *ngIf="industryCategoryDataPage?.pagesCount() > 1 && !isLoading"
                   [pageSizeOptions]="[5, 10, 20, 100]"
                   [pageSize]="industryCategoryDataPage?.limit"
                   (page)="setPage($event)"
                   [length]="industryCategoryDataPage?.itemsCount"
                   [pageIndex]="industryCategoryDataPage?.page - 1"></mat-paginator>
  </div>

</div>
