<app-admin-toolbar [isButtonDisabled]="isLoading || !companyData"
                   [toolbarTitle]="'admin.company.edit.title'"
                   (buttonAction)="toolbarAction($event)"></app-admin-toolbar>

<div class="admin-create-content">
  <app-company-form *ngIf="!isLoading && companyData; else showLoader"
                    [isEditMode]="true"
                    [editData]="companyData"></app-company-form>
  <ng-template #showLoader>
    <app-custom-spinner></app-custom-spinner>
  </ng-template>
</div>
