import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class AssetsService {

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
  }

  public setIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'logo-dpx-color',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/logo-dpx-color.svg'));

    this.matIconRegistry.addSvgIcon(
      'logo-dpx',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/logo-dpx.svg'));

    this.matIconRegistry.addSvgIcon(
      'email',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/email.svg'));

    this.matIconRegistry.addSvgIcon(
      'password',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/password.svg'));

    this.matIconRegistry.addSvgIcon(
      'hamburger',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/hamburger.svg'));

    this.matIconRegistry.addSvgIcon(
      'menu-contact',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-contact.svg'));

    this.matIconRegistry.addSvgIcon(
      'menu-educational-materials',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-educational-materials.svg'));

    this.matIconRegistry.addSvgIcon(
      'menu-finances',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-finances.svg'));

    this.matIconRegistry.addSvgIcon(
      'menu-my-project-view',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-my-project-view.svg'));

    this.matIconRegistry.addSvgIcon(
      'menu-project-view',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-project-view.svg'));

    this.matIconRegistry.addSvgIcon(
      'menu-recipients',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-recipients.svg'));

    this.matIconRegistry.addSvgIcon(
      'menu-chat',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-chat.svg'));

    this.matIconRegistry.addSvgIcon(
      'menu-logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-logout.svg'));

    this.matIconRegistry.addSvgIcon(
      'menu-notifications',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-notifications.svg'));

    this.matIconRegistry.addSvgIcon(
      'menu-settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-settings.svg'));

    this.matIconRegistry.addSvgIcon(
      'project-category',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/project-category.svg'));
  }

  public getAssetUrl(name?: string, suffix?: number): string {
    let fileUrl = 'assets/img/1.svg';
    if (name) {
      if (!suffix) {
        fileUrl = `assets/img/${name}`;
      } else {
        const nameParts = name.split('.');
        fileUrl = `assets/img/${nameParts[0]}__${suffix}.${nameParts[1]}`;
      }
    }

    return fileUrl;
  }

  public getAvatarUrl(name?: string, suffix?: number): string {
    let fileUrl = 'assets/images/empty-avatar-300x300.png';
    if (name) {
      if (!suffix) {
        fileUrl = `${environment.assetsUrl.avatars}/${name}`;
      } else {
        const nameParts = name.split('.');
        fileUrl = `${environment.assetsUrl.avatars}/${nameParts[0]}__${suffix}.${nameParts[1]}`;
      }
    }

    return fileUrl;
  }

}
