<div class="admin-table-container">
  <div class="table-toolbar">
    <div class="table-toolbar-filters">
      <div class="table-toolbar-filters-label"> {{ 'admin.company.table.filters.label' | translate }}</div>
      <form [formGroup]="filtersForm">
        <mat-checkbox class="table-toolbar-filters-checkbox"
                      disableRipple
                      [formControl]="filtersForm.controls['deleted']">
          {{'admin.company.table.filters.showDeletedCheckbox' | translate }}
        </mat-checkbox>
        <mat-checkbox class="table-toolbar-filters-checkbox"
                      disableRipple
                      [formControl]="filtersForm.controls['isContractor']">
          {{'admin.company.table.filters.isContractor' | translate }}
        </mat-checkbox>
        <mat-checkbox class="table-toolbar-filters-checkbox"
                      disableRipple
                      [formControl]="filtersForm.controls['isCustomer']">
          {{'admin.company.table.filters.isCustomer' | translate }}
        </mat-checkbox>
      </form>
    </div>
    <div class="table-toolbar-button">
      <button class="my-custom-button" mat-button (click)=" navigateToAddCompany()">
        {{ 'common.buttons.add' | translate }}
      </button>
    </div>
  </div>

  <div class="table-content">
    <table mat-table [dataSource]="tableDataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> {{ 'admin.company.table.header.id' | translate }}</th>
        <td mat-cell
            [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even;  let row">{{row.id}}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{ 'admin.company.table.header.name' | translate }}</th>
        <td mat-cell
            [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even; let row">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> {{ 'admin.company.table.header.address' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'" *matCellDef="let even = even; let row">
          {{row.street}} {{row.streetNo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef> {{ 'admin.company.table.header.city' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'" *matCellDef="let even = even; let row">
          {{row.city}}
        </td>
      </ng-container>

      <ng-container matColumnDef="contractor">
        <th mat-header-cell *matHeaderCellDef> {{ 'admin.company.table.header.isContractor' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'" *matCellDef="let even = even; let row">
          {{row.isContractor | booleanTranslate}}
      </ng-container>

      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef> {{ 'admin.company.table.header.isCustomer' | translate }}</th>
        <td mat-cell [ngClass]="even ? 'table-row-even' : 'table-row-odd'" *matCellDef="let even = even; let row">
          {{row.isCustomer | booleanTranslate}}
      </ng-container>

      <ng-container matColumnDef="deleted">
        <th mat-header-cell *matHeaderCellDef> {{ 'admin.company.table.header.deleted' | translate }}</th>
        <td mat-cell
            [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            [class.item-deleted]="row.deleted"
            *matCellDef="let even = even; let row">
          {{row.deleted | booleanTranslate}}
      </ng-container>

      <ng-container matColumnDef="actions">
        <th class="admin-table-action-column" mat-header-cell *matHeaderCellDef></th>
        <td class="admin-table-action-column"
            mat-cell
            [ngClass]="even ? 'table-row-even' : 'table-row-odd'"
            *matCellDef="let even = even; let row;"
            [class.show-buttons]="rowOnHover === row.id">

          <button *ngIf="rowProcessingId !== row.id" mat-icon-button (click)=" navigateToEditCompany(row.id)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="rowProcessingId !== row.id" mat-icon-button (click)="deleteCompany(row.id)">
            <mat-icon>delete</mat-icon>
          </button>
          <div class="table-action-spinner" *ngIf="rowProcessingId === row.id">
            <mat-spinner></mat-spinner>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumnsHeader; sticky: true"></tr>
      <tr [class.disable-row]="rowProcessingId === row.id"
          (mouseenter)="rowOnHover = row.id"
          (mouseleave)="rowOnHover = null"
          mat-row
          *matRowDef="let row; columns: tableColumnsHeader;"></tr>
    </table>

    <app-custom-spinner *ngIf="isLoading"></app-custom-spinner>

    <div class="no-data" *ngIf="!companyDataPage?.items.length && !isLoading">
      {{ 'common.noData' | translate }}
    </div>

    <mat-paginator *ngIf="companyDataPage?.pagesCount() > 1 && !isLoading"
                   [pageSizeOptions]="[5, 10, 20, 100]"
                   [pageSize]="companyDataPage?.limit"
                   (page)="setPage($event)"
                   [length]="companyDataPage?.items.length"
                   [pageIndex]="companyDataPage?.page - 1"></mat-paginator>
  </div>

</div>
