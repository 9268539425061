import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

export function objectToParams(object, dateFormat?: string): string {
  if (object) {
    return _.compact(Object.keys(object).map((key) => {
        if (_.isObject(object[key])) {
          return subObjectToParams(encodeURIComponent(key), object[key], dateFormat);
        } else {
          if (object[key] || object[key] === false) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`;
          } else {
            return '';
          }
        }
      }
    )).join('&');
  } else {
    return '';
  }
}

function subObjectToParams(key, object, dateFormat?: string): string {
  if (!dateFormat) {
    dateFormat = 'yyyy-MM-dd HH:mm:ss';
  }
  return Object.keys(object).map((childKey) => {
    if (_.isObject(object[childKey] && !(object[childKey] instanceof Date)) || object[childKey] === false) {
      return subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]);
    } else {
      if (object[childKey]) {
        if (object[childKey] instanceof Date) {
          const date = new DatePipe(object[childKey]).transform(object[childKey], dateFormat);
          return `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(date)}`;
        } else if (_.isObject(object[childKey])) {
          const paramString = [];
          _.each(object[childKey], (val, keyItem) => {
            paramString.push(`${key}[${encodeURIComponent(childKey)}][${encodeURIComponent(keyItem + '')}]=${encodeURIComponent(val)}`);
          });
          const h = paramString.join('&');
          return h;
        } else {
          return `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(object[childKey])}`;
        }
      } else {
        return undefined;
      }
    }
  }).filter(function (item) {
    return (item);
  }).join('&');
}
