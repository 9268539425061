<div class="app-container">
  <div class="sidebar" [class.sidebar-open]="isSidebarOpen">
    <app-sidebar (sidebarToggle)="toggleSidebar($event)"></app-sidebar>
  </div>

  <div class="main-content">
    <div class="header">
      <app-navbar></app-navbar>
    </div>

    <div class="main-content-router">
      <perfect-scrollbar>
        <router-outlet></router-outlet>
      </perfect-scrollbar>
    </div>
  </div>
</div>
