import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService,
              public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const accessRole = route.data.accessRole;
    if (this.authenticationService.canAccessRole(accessRole)) {
      return true;
    } else {
      this.router.navigate(['main/projects/list']);
      return false;
    }
  }
}
