import { ProvniceDtoModel } from './provnice.dto.model';

export class CompanyDtoModel {
  public id?: number;
  public name?: string;
  public createdDate?: Date;
  public street?: string;
  public streetNo?: string;
  public city?: string;
  public avatar?: string;
  public province?: ProvniceDtoModel;
  public isCustomer?: boolean;
  public isContractor?: boolean;
  public deleted?: boolean;

  constructor(obj?: CompanyDtoModel) {
    this.name = obj && obj.name || null;
    this.createdDate = obj && obj.createdDate ? new Date(obj.createdDate) : null;
    this.street = obj && obj.street || null;
    this.streetNo = obj && obj.streetNo || null;
    this.city = obj && obj.city || null;
    this.avatar = obj && obj.avatar || null;
    this.province = obj && obj.province ? new ProvniceDtoModel(obj.province) : null;
    this.isCustomer = obj && obj.isCustomer || true;
    this.isContractor = obj && obj.isContractor || true;
    this.id = obj && obj.id || null;
    this.deleted = obj && obj.deleted || false;
  }
}
