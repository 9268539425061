import { map } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { objectToParams } from '../../shared/utils/object-to-params';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserProfileModel } from '../model/user-profile.model';
import { User } from '../model/user.model';

@Injectable()
export class UserService {
  public onLoggedUser$: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.onLoggedUser$ = new EventEmitter();
  }

  public changePassword(request: object): any {
    const apiUrl = `${environment.apiUrl}/users/password`;
    return this.http.put(apiUrl, request);
  }

  public register(request: object): Observable<any> {
    let params = {};
    params['XDEBUG_SESSION_START'] = 'PHPSTORM';

    params = objectToParams(params);
    const apiUrl = `${environment.apiUrl}/register?${params}`;
    return this.http.post(apiUrl, request);
  }

  public activateLogin(request: object): any {
    const apiUrl = `${environment.apiUrl}/activate-login`;
    return this.http.post(apiUrl, request);
  }

  public getCurrentLoggedUser(): Observable<UserProfileModel> {
    let apiUrl: string;

    apiUrl = `${environment.apiUrl}/me`;

    return this.http.get(apiUrl).pipe(map((data: UserProfileModel) => new UserProfileModel(data)));
  }

  public resetPasswordRequest(request: object): any {
    let params = {};
    params['XDEBUG_SESSION_START'] = 'PHPSTORM';

    params = '?' + objectToParams(params);

    const apiUrl = `${environment.apiUrl}/reset-password-request${params}`;
    return this.http.post(apiUrl, {username: request});
  }

  public resetPassword(request: any): any {
    let params = {};
    params['XDEBUG_SESSION_START'] = 'PHPSTORM';

    params = '?' + objectToParams(params);

    const apiUrl = `${environment.apiUrl}/reset-password${params}`;
    return this.http.post(apiUrl, request);
  }

  public checkUserEmailAvailable(emailToCheck: string): Observable<User> {
    return this.http.post(`${environment.apiUrl}/admin/users/check-email`, {email: emailToCheck})
      .pipe(map((response: User) => new User(response)));
  }
}
