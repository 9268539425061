import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavbarService } from './navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass']
})
export class NavbarComponent implements OnDestroy {
  public navbarTitle: string;

  private navbarSubscription: Subscription;

  constructor(private navbarService: NavbarService) {
    this.navbarTitle = '';
    this.getTitle();
  }

  public searchInput(event: string): void {}

  public ngOnDestroy(): void {
    this.navbarSubscription.unsubscribe();
  }

  private getTitle(): void {
    this.navbarSubscription = this.navbarService.getNavbarTitle()
      .subscribe((value: string) => {
        this.navbarTitle = value;
      });
  }
}
