import * as _ from 'lodash';
import { ProjectProjectQuestionFileDtoModel } from './project-project-question-file.dto.model';

import { ProjectQuestionDtoModel } from './project-question.dto.model';
import { ProjectDtoModel } from './project.dto.model';

export class ProjectProjectQuestionDtoModel {
  public id: number;
  public project: ProjectDtoModel;
  public projectQuestion: ProjectQuestionDtoModel;
  public children: Array<ProjectProjectQuestionDtoModel>;
  public files: Array<ProjectProjectQuestionFileDtoModel>;
  public parent: ProjectProjectQuestionDtoModel;
  public answer: string;

  constructor(data?: any) {
    this.id = data && data.id || null;
    this.project = data && data.project ? new ProjectDtoModel(data.project) : null;
    this.projectQuestion = data && data.projectQuestion ? new ProjectQuestionDtoModel(data.projectQuestion) : null;
    this.answer = data && data.answer || null;
    this.parent = data && data.parent ? new ProjectProjectQuestionDtoModel(data.parent) : null;
    this.projectQuestion = data && data.projectQuestion ? new ProjectQuestionDtoModel(data.projectQuestion) : null;
    this.children = data && data.children ?
      _.map(data.children, (child: ProjectProjectQuestionDtoModel) =>
        new ProjectProjectQuestionDtoModel(child)) : [];
    this.files = data && data.files ?
      _.map(data.files, (file: ProjectProjectQuestionFileDtoModel) =>
        new ProjectProjectQuestionFileDtoModel(file)) : [];
  }
}
