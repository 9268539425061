<div class="mat-dialog-header">
  <div>{{ data.title }}</div>
</div>
<mat-dialog-content>
  <p>
    {{ data.message }}
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button matDialogClose>NIE</button>
  <button mat-button class="my-custom-button" (click)="ok()">TAK</button>
</mat-dialog-actions>

