import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { CompanyDtoService } from '../../../../core/service/company.dto.service';
import { ToasterService } from '../../../../shared/utils/toaster.service';
import { CompanyFormComponent } from '../shared/company-form/company-form.component';
import { CompanyCreateDtoModel } from '../../../../core/model/company-create.dto.model';
import { CompanyDtoModel } from '../../../../core/model/company.dto.model';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html'
})
export class CompanyCreateComponent {
  public isLoading: boolean;

  @ViewChild(CompanyFormComponent, {static: false}) companyFormComponent: any;

  constructor(private companyService: CompanyDtoService,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private route: ActivatedRoute,
              private router: Router) {
    this.isLoading = false;
  }

  public toolbarAction(actionEventLabel: string): void {
    switch (actionEventLabel) {
      case 'BACK':
        this.back();
        break;
      case 'SAVE':
        this.save();
        break;
    }
  }

  private back(): void {
    this.router.navigate(['../list'], {relativeTo: this.route});
  }

  private save(): void {
    const requestData: CompanyCreateDtoModel = this.companyFormComponent.save();
    if (requestData) {
      this.isLoading = true;
      this.companyService.createCompanyAdmin(requestData)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((data: CompanyDtoModel) => {
          if (data) {
            this.toasterService.showSuccess([this.translateService.instant('common.toasters.successAdd')]);
            this.back();
          }
        }, (err: HttpErrorResponse) => {
          if (err && err.error && err.error.message) {
            this.toasterService.showError([err.error.message]);
          } else {
            this.toasterService.showError([this.translateService.instant('common.toasters.unknownError')]);
          }
        });
    }
  }
}
