<div class="view-container">
  <div class="view-column">
    <div class="project-content">
      <div class="project-header">
        <div class="avatar" [style]="{ 'background-image': avatarBgCssStyleValue }"></div>
        <div class="title">
          <h1>{{ projectData?.name }}</h1>
        </div>
      </div>

      <div class="project-questions">
        <app-project-collecting-contractors [project]="projectData"></app-project-collecting-contractors>
      </div>
    </div>

    <div class="project-additional">
      <div class="project-details">
        <div class="project-details-group">
          <div class="project-details-group-title">
            {{ 'project.details' | translate }}
          </div>
          <div class="project-details-group-content">
            <div class="details-group-item">
              <div class="details-group-icon">
                <div class="icon icon-user" [inlineSVG]="'user.svg'"></div>
              </div>
              <div class="details-group-label">
                {{ customerLabel }}
              </div>
            </div>

            <div class="details-group-item">
              <div class="details-group-icon">
                <div class="icon icon-project-category" [inlineSVG]="'project-category.svg'"></div>
              </div>
              <div class="details-group-label">
                {{ projectData?.industryCategory.name }}
              </div>
            </div>

            <div class="details-group-item">
              <div class="details-group-icon">
                <app-project-stage-icon [stage]="projectData?.stage"></app-project-stage-icon>
              </div>
              <div class="details-group-label">
                {{ 'project.stage.' + projectData?.stage | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="project-details-group">
          <div class="project-details-group-title">
            {{ 'project.remainingTime' | translate }}
          </div>
          <div class="project-details-group-content">
            <div class="details-group-item">
              <div class="details-group-icon">
                <div class="icon icon-date" [inlineSVG]="'data.svg'"></div>
              </div>
              <div class="details-group-label">
                <div class="time-remaining"
                     *ngIf="projectData?.stage === projectStageEnum.collectingContractors"
                     matTooltip="{{ projectData?.stageOneFinishDate | date: 'dd/MM/yyyy HH:mm' }}">
                  {{projectData?.stageOneFinishDate | amDifference: today :'days' : true | round }} dni
                </div>
                <div class="time-remaining"
                     *ngIf="projectData?.stage === projectStageEnum.advanced"
                     matTooltip="{{ projectData?.stageTwoFinishDate | date: 'dd/MM/yyyy HH:mm' }}">
                  {{projectData?.stageTwoFinishDate | amDifference: today :'days' : true | round }} dni
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="project-details-group">
          <div class="project-details-group-title">
            {{ 'project.projectStatus' | translate }}
          </div>
          <div class="project-details-group-content">
            <div class="details-group-item">
              <div class="details-group-icon">
                <div class="icon icon-info" [inlineSVG]="'informacje.svg'"></div>
              </div>
              <div class="details-group-label">
                {{ 'project.status.' + projectData?.status | translate }}
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="project-attendance project-details">
        <ng-container *ngIf="currentUser">
          <div>
            <div>
              <h3><strong>Jesteś</strong> zainteresowany?</h3>
            </div>
            <div class="yes-or-no">
              <div class="yes" (click)="join()">Dołącz</div>
            </div>
          </div>
        </ng-container>
      </div>

<!--      <app-project-collecting-contractors [project]="projectData"></app-project-collecting-contractors>-->

<!--      <div class="two-column">-->
<!--        <div class="remaining-time box-card shadow-box-default">-->
<!--          <div class="remaining-time-icon">-->
<!--            <mat-icon>query_builder</mat-icon>-->
<!--          </div>-->
<!--          <div class="time-remaining"-->
<!--               *ngIf="projectData?.stage === projectStageEnum.collectingContractors"-->
<!--               matTooltip="{{ projectData?.stageOneFinishDate | date: 'dd/MM/yyyy HH:mm' }}">-->
<!--            {{projectData?.stageOneFinishDate | amDifference: today :'days' : true | round }} dni-->
<!--          </div>-->
<!--          <div class="time-remaining"-->
<!--               *ngIf="projectData?.stage === projectStageEnum.advanced"-->
<!--               matTooltip="{{ projectData?.stageTwoFinishDate | date: 'dd/MM/yyyy HH:mm' }}">-->
<!--            {{projectData?.stageTwoFinishDate | amDifference: today :'days' : true | round }} dni-->
<!--          </div>-->
<!--          <small>pozostało do końca etapu</small>-->
<!--        </div>-->

<!--        <div class="stage box-card shadow-box-default">-->
<!--          <div class="stage-1"-->
<!--               [class.current]="projectData?.stage === projectStageEnum.collectingContractors"-->
<!--               [class.completed]="projectData?.stage !== projectStageEnum.collectingContractors">-->
<!--            <div class="icon" *ngIf="projectData?.stage === projectStageEnum.collectingContractors">-->
<!--              <mat-icon>assignment</mat-icon>-->
<!--            </div>-->
<!--            <div class="icon" *ngIf="projectData?.stage !== projectStageEnum.collectingContractors">-->
<!--              <mat-icon>check</mat-icon>-->
<!--            </div>-->
<!--            <div>Zapytanie wstępne</div>-->
<!--          </div>-->
<!--          <div class="stage-2"-->
<!--               [class.current]="projectData?.stage === projectStageEnum.advanced"-->
<!--               [class.completed]="projectData?.stage === projectStageEnum.implementation">-->
<!--            <div class="icon">-->
<!--              <div class="icon" *ngIf="projectData?.stage === projectStageEnum.advanced">-->
<!--                <mat-icon>assignment</mat-icon>-->
<!--              </div>-->
<!--              <div class="icon" *ngIf="projectData?.stage === projectStageEnum.implementation">-->
<!--                <mat-icon>check</mat-icon>-->
<!--              </div>-->
<!--              <div class="icon" *ngIf="projectData?.stage === projectStageEnum.collectingContractors">-->
<!--                <mat-icon>hourglass_full</mat-icon>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div>Zapytanie zaawansowane</div>-->
<!--          </div>-->
<!--          <div class="stage-3">-->
<!--            <div class="icon">-->
<!--              <mat-icon>hourglass_full</mat-icon>-->
<!--            </div>-->
<!--            <div>Wdrożenie</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="box-card shadow-box-default add-offer" *ngIf="projectData?.stage === projectStageEnum.advanced">-->
<!--        <mat-icon>library_add</mat-icon>-->
<!--        Złóż ofertę-->
<!--      </div>-->

<!--      <ng-container *ngIf="currentUser">-->
<!--        <div class="box-card shadow-box-default interested">-->
<!--          <div class="box-header">-->
<!--            <h3><strong>Jesteś</strong> zainteresowany?</h3>-->
<!--          </div>-->
<!--          <div class="yes-or-no">-->
<!--            <div class="yes">TAK</div>-->
<!--            <div class="no">NIE</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-container>-->

<!--      <div class="box-card shadow-box-default asked-questions" *ngIf="projectData?.stage === 1">-->
<!--        <div class="box-header">-->
<!--          <div>-->
<!--            <h3><strong>Zadane</strong> pytania</h3>-->
<!--          </div>-->
<!--          <div>-->
<!--            <button mat-flat-button class="my-small-button" color="primary" (click)="showQuestionCreatePopup()">Zadaj-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="question-list">-->
<!--          <mat-accordion>-->
<!--            <mat-expansion-panel>-->
<!--              <mat-expansion-panel-header>-->
<!--                <mat-panel-title>-->
<!--                  Czy pełna dokumentacja zostanie dostarczona przed rozpoczączęciem prac ?-->
<!--                </mat-panel-title>-->
<!--              </mat-expansion-panel-header>-->

<!--              <p>Jest dostępnych wiele różnych wersji Lorem Ipsum, ale większość zmieniła się pod wpływem dodanego-->
<!--                humoru czy przypadkowych słów, które nawet w najmniejszym stopniu nie przypominają istniejących. Jeśli-->
<!--                masz zamiar użyć fragmentu Lorem Ipsum, lepiej mieć pewność, że nie ma niczego „dziwnego” w środku-->
<!--                tekstu. Wszystkie Internetowe generatory Lorem Ipsum mają tendencje do kopiowania już istniejących-->
<!--                bloków, co czyni nasz pierwszym prawdziwym generatorem w Internecie. Używamy zawierającego ponad 200-->
<!--                łacińskich słów słownika, w kontekście wielu znanych sentencji, by wygenerować tekst wyglądający-->
<!--                odpowiednio. To wszystko czyni „nasz” Lorem Ipsum wolnym od powtórzeń, humorystycznych wstawek czy-->
<!--                niecharakterystycznych słów.</p>-->
<!--            </mat-expansion-panel>-->
<!--            <mat-expansion-panel>-->
<!--              <mat-expansion-panel-header>-->
<!--                <mat-panel-title>-->
<!--                  Czy to wszystko czyni „nasz” Lorem Ipsum wolnym od powtórzeń ?-->
<!--                </mat-panel-title>-->
<!--              </mat-expansion-panel-header>-->
<!--              <p>Jest dostępnych wiele różnych wersji Lorem Ipsum, ale większość zmieniła się pod wpływem dodanego-->
<!--                humoru czy przypadkowych słów, które nawet w najmniejszym stopniu nie przypominają istniejących. Jeśli-->
<!--                masz zamiar użyć fragmentu Lorem Ipsum, lepiej mieć pewność, że nie ma niczego „dziwnego” w środku-->
<!--                tekstu. Wszystkie Internetowe generatory Lorem Ipsum mają tendencje do kopiowania już istniejących-->
<!--                bloków, co czyni nasz pierwszym prawdziwym generatorem w Internecie. Używamy zawierającego ponad 200-->
<!--                łacińskich słów słownika, w kontekście wielu znanych sentencji, by wygenerować tekst wyglądający-->
<!--                odpowiednio. To wszystko czyni „nasz” Lorem Ipsum wolnym od powtórzeń, humorystycznych wstawek czy-->
<!--                niecharakterystycznych słów.</p>-->
<!--            </mat-expansion-panel>-->
<!--          </mat-accordion>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="box-card shadow-box-default files">-->
<!--        <div class="box-header">-->
<!--          <h3><strong>Pliki</strong> do pobrania</h3>-->
<!--        </div>-->

<!--        <div class="files-list">-->
<!--          <div class="file">-->
<!--            <div class="file-label">projekt_hali.dwg</div>-->
<!--            <mat-icon>get_app</mat-icon>-->
<!--          </div>-->
<!--          <div class="file">-->
<!--            <div class="file-label">wytyczne_dzialu_nz.pdf</div>-->
<!--            <mat-icon>get_app</mat-icon>-->
<!--          </div>-->
<!--          <div class="file">-->
<!--            <div class="file-label">zestawienie.xls</div>-->
<!--            <mat-icon>get_app</mat-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

    </div>
  </div>
</div>
