import { Component, Input } from '@angular/core';
import { AdminNavbarItemModel } from '../../../core/model/admin-navbar-item.model';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.sass']
})
export class AdminNavbarComponent {
  @Input() navbarItems: Array<AdminNavbarItemModel>;
}
