import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { MaterialComponentsModule } from '../core/material/material-components.module';
import { NotFoundComponent } from '../pre/not-found/not-found.component';
import { AdminNavbarComponent } from './component/admin-navbar/admin-navbar.component';
import { AdminToolbarComponent } from './component/admin-toolbar/admin-toolbar.component';
import { CustomSpinnerComponent } from './component/custom-spinner/custom-spinner.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { PaginatorComponent } from './component/paginator/paginator.component';
import { ProjectStageIconComponent } from './component/project-stage-icon/project-stage-icon.component';
import { SearchInputComponent } from './component/search-input/search-input.component';
import { SidebarItemComponent } from './component/sidebar/sidebar-item/sidebar-item.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { ToasterComponent } from './component/toaster/toaster.component';
import { BooleanTranslatePipe } from './pipe/boolean-translate.pipe';
import { RoundPipe } from './pipe/round.pipe';
import { StringPrefixPipe } from './pipe/string-prefix.pipe';
import { AlertPopupComponent } from './popup/alert-popup/alert-popup.component';
import { ConfirmPopupComponent } from './popup/confirm-popup/confirm-popup.component';
import { ClickOutsideDirective } from './utils/click-outside.directive';
import { PopupHelper } from './utils/popupHelper';
import { UserMenuComponent } from './component/user-menu/user-menu.component';

@NgModule({
  declarations: [
    NavbarComponent,
    ToasterComponent,
    StringPrefixPipe,
    SidebarComponent,
    SidebarItemComponent,
    PaginatorComponent,
    NotFoundComponent,
    AlertPopupComponent,
    ConfirmPopupComponent,
    ClickOutsideDirective,
    RoundPipe,
    SearchInputComponent,
    PageNotFoundComponent,
    CustomSpinnerComponent,
    AdminNavbarComponent,
    AdminToolbarComponent,
    UserMenuComponent,
    BooleanTranslatePipe,
    ProjectStageIconComponent
  ],
  exports: [
    AdminToolbarComponent,
    AdminNavbarComponent,
    NavbarComponent,
    ToasterComponent,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    StringPrefixPipe,
    SidebarComponent,
    PaginatorComponent,
    CustomSpinnerComponent,
    RoundPipe,
    BooleanTranslatePipe,
    ProjectStageIconComponent
  ],
  entryComponents: [
    ToasterComponent,
    ConfirmPopupComponent
  ],
  providers: [
    PopupHelper
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    InlineSVGModule
  ]
})
export class SharedModule {
}
