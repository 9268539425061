import { Injectable } from '@angular/core';

@Injectable()
export class IndustryCategoryDtoModel {
  public id?: number;
  public name: string;
  public color: string;


  constructor(obj?: IndustryCategoryDtoModel) {
    this.id = obj && obj.id || null;
    this.name = obj && obj.name || null;
    this.color = obj && obj.color || null;
  }
}
