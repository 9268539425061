import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localePlCaExtra from '@angular/common/locales/extra/pl';
import localePlCa from '@angular/common/locales/pl';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InlineSVGModule } from 'ng-inline-svg';
import { MomentModule } from 'ngx-moment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appStoreProviders } from './app.store';
import { AuthenticationService } from './core/authentication/authentication.service';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/interceptor/auth/auth.service';
import { UserService } from './core/service/user.service';
import { AdminModule } from './main/admin/admin.module';
import { MainModule } from './main/main.module';
import { ProjectModule } from './main/project/project.module';
import { PreModule } from './pre/pre.module';
import { NavbarService } from './shared/component/navbar/navbar.service';
import { SidebarService } from './shared/component/sidebar/sidebar.service';
import { SharedModule } from './shared/shared.module';
import { AssetsService } from './shared/utils/assets.service';
import { ToasterService } from './shared/utils/toaster.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ApiModule } from './shared/service-proxies';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localePlCa, localePlCaExtra);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    ApiModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    PerfectScrollbarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    CoreModule,
    InlineSVGModule.forRoot({baseUrl: '/assets/icons/'}),
    SharedModule,
    PreModule,
    BrowserModule,
    MainModule,
    MomentModule,
    AdminModule,
    ProjectModule
  ],
  providers: [
    appStoreProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AuthenticationService,
    UserService,
    ToasterService,
    NavbarService,
    AssetsService,
    SidebarService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
