import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../core/service/user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.sass']
})
export class ActivateComponent implements OnDestroy {
  private sub: any;
  public success: boolean;
  public error: boolean;
  public loading: boolean;

  constructor(private userService: UserService,
              private translateService: TranslateService,
              private route: ActivatedRoute,
              private router: Router) {
    this.sub = this.route.params.subscribe(params => {
      const token = atob(params['token']);
      const req = JSON.parse(token);
      this.loading = true;

      this.userService.activateLogin(req).subscribe(() => {
        this.success = true;
        this.loading = false;
      }, (err: HttpErrorResponse) => {
        if (err && err.error && err.error.error) {
          this.error = true;
          this.loading = false;
        }
      });
    });

  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  goToForgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  ngOnDestroy() {
    if (this.sub && this.sub.unsubscribe) {
      this.sub.unsubscribe();
    }
  }
}
