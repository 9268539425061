import { NgModule } from '@angular/core';
import { AccessGuardService } from './access-guard.service';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { AuthenticationService } from './authentication/authentication.service';

import { MaterialComponentsModule } from './material/material-components.module';
import { ProjectQuestionDtoService } from './service/project-question.dto.service';
import { ProjectDtoService } from './service/project.dto.service';


@NgModule({
  imports: [],
  exports: [
    MaterialComponentsModule
  ],
  declarations: [],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    AccessGuardService,
    ProjectDtoService,
    ProjectQuestionDtoService
  ]
})

export class CoreModule {
}
