import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';

import { environment } from '../../../../../environments/environment';

import { ProjectProjectQuestionDtoModel } from '../../../../core/model/project-project-question.dto.model';
import { ProjectDtoModel } from '../../../../core/model/project.dto.model';
import { UserProjectQuestionDtoModel } from '../../../../core/model/user-project-question.dto.model';
import { ProjectQuestionDtoService } from '../../../../core/service/project-question.dto.service';
import { ProjectQuestionCategoryEnum } from '../../../../shared/enum/project-question-category.enum';
import { ProjectStageEnum } from '../../../../shared/enum/project-stage.enum';
import { DataPageModel } from '../../../../shared/model/data-page.model';

@Component({
  selector: 'app-project-collecting-contractors',
  templateUrl: './project-collecting-contractors.component.html',
  styleUrls: ['./project-collecting-contractors.component.sass']
})
export class ProjectCollectingContractorsComponent {
  @Input()
  public set project(project: ProjectDtoModel) {

    this.projectData = project;
    if (project) {
      this.projectQuestionDtoService.getList(this.projectData.id).subscribe((data: DataPageModel<UserProjectQuestionDtoModel>) => {
        this.userQuestions = data.items;
      });
      if (this.projectData.stage === ProjectStageEnum.advanced) {
        const filtered = _.filter(this.projectData.projectProjectQuestions, (question: ProjectProjectQuestionDtoModel) => !question.parent);
        const grouped = _.groupBy(filtered, (question: ProjectProjectQuestionDtoModel) => {
          return question.projectQuestion.category.id;
        }) as any;
        this.questionGrouped = grouped;
      }
    } else {
      this.questionGrouped = {};
    }
  }

  public get project(): ProjectDtoModel {
    return this.projectData;
  }

  public questionNewControl: FormControl;

  public userQuestions: Array<UserProjectQuestionDtoModel>;

  public projectStageEnum = ProjectStageEnum;
  public projectQuestionCategoryEnum = ProjectQuestionCategoryEnum;
  public questionGrouped: any;

  public showSuccess: boolean;

  private projectData: ProjectDtoModel;


  constructor(private projectQuestionDtoService: ProjectQuestionDtoService) {
    this.questionNewControl = new FormControl();
  }

  public getAttachmentUrl(fileName: string) {
    return `${environment.assetsUrl.project}/${fileName}`;
  }

  public sendQuestion() {
    if (this.questionNewControl.value) {
      this.showSuccess = true;

      this.projectQuestionDtoService.create(this.projectData.id, this.questionNewControl.value)
        .subscribe((done: UserProjectQuestionDtoModel) => {
        console.log(done);
          this.questionNewControl.setValue('');
        setTimeout(() => {
          this.showSuccess = false;
        }, 3000);
      }, () => {
          setTimeout(() => {
            this.questionNewControl.setValue('');

            this.showSuccess = false;
          }, 3000);
        });
    }
  }

}
