import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { MomentModule } from 'ngx-moment';
import { SharedModule } from '../../shared/shared.module';
import { ProjectListComponent } from './shared/components/project-list/project-list.component';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectCollectingContractorsComponent } from './project-view/project-collecting-contractors/project-collecting-contractors.component';
import { ProjectQuestionCreatePopupComponent } from './project-view/project-question-create-popup/project-question-create-popup.component';
import { ProjectViewComponent } from './project-view/project-view.component';
import { ProjectComponent } from './project.component';
import { ProjectsComponent } from './projects/projects.component';
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MomentModule,
    ProjectRoutingModule,
    InlineSVGModule
  ],
  entryComponents: [
    ProjectQuestionCreatePopupComponent
  ],
  declarations: [
    ProjectComponent,
    ProjectsComponent,
    ProjectViewComponent,
    ProjectCollectingContractorsComponent,
    ProjectQuestionCreatePopupComponent,
    ProjectListComponent
  ],
  exports: [],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pl',
    }
  ],
  schemas: []
})
export class ProjectModule {
}
