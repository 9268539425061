import { Action, ActionCreator } from 'redux';
import { UserProfileModel } from '../../core/model/user-profile.model';

export const SET_CURRENT_USER = '[User] Set Current';

export interface SetCurrentUserAction extends Action {
  user: UserProfileModel;
}

export const setCurrentUser: ActionCreator<SetCurrentUserAction> =
  (user) => ({
    type: SET_CURRENT_USER,
    user: user
  });


export const GET_CURRENT_USER = '[User] Get Current';


export const getCurrentUser: ActionCreator<Action> =
  () => ({
    type: GET_CURRENT_USER
  });

