import * as _ from 'lodash';
import { ProjectProjectQuestionDtoModel } from './project-project-question.dto.model';
import { ProjectQuestionCategoryDtoModel } from './project-question-category.dto.model';

export class ProjectQuestionDtoModel {
  public id: number;
  public version: string;
  public description: string;
  public projectProjectQuestions: Array<ProjectProjectQuestionDtoModel>;
  public category: ProjectQuestionCategoryDtoModel;
  public question: string;
  public stage: number;

  constructor(data?: any) {
    this.id = data && data.id || null;
    this.version = data && data.version || null;
    this.description = data && data.description || null;
    this.projectProjectQuestions = data && data.projectProjectQuestions ?
      _.map(data.projectProjectQuestions, (projectQuestion) => new ProjectProjectQuestionDtoModel(projectQuestion)) : [];
    this.category = data && data.category ? new ProjectQuestionCategoryDtoModel(data.category) : null;
    this.question = data && data.question || null;
    this.stage = data && data.stage || null;
  }
}
