import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../core/service/user.service';
import { ToasterService } from '../../shared/utils/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit, AfterViewChecked {
  registerForm: FormGroup;
  email: string;
  sub: any;
  loading: boolean;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private toasterService: ToasterService,
              private router: Router,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService) {
    this.createForm();
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.email = params['email'];
    });

    if (this.router.url === '/register' && this.authenticationService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  ngAfterViewChecked() {
  }

  areEqual(formGroup) {
    if (formGroup.controls['second'].value &&
      formGroup.controls['first'].value !== formGroup.controls['second'].value) {
      return {
        areEqual: true
      };
    } else if (formGroup.controls['first'].value === formGroup.controls['second'].value) {
      return null;
    }
  }

  register() {
    if (this.registerForm.valid) {
      const registerDataRequest = {
        email: this.registerForm.controls['email'].value,
        passwords: {
          first: this.registerForm.controls['passwords']['controls']['first'].value,
          second: this.registerForm.controls['passwords']['controls']['second'].value,
        }
      };
      this.loading = true;
      this.userService.register(registerDataRequest).subscribe(() => {
        this.toasterService.showSuccess('Konto zostało założone. Sprawdź e-mail i kliknij w link aktywujący');
        this.router.navigate(['/']);
        this.loading = false;
      }, (err: HttpErrorResponse) => {
        const error: HttpErrorResponse = err.error;
        this.toasterService.showError(error['errors']);
        this.loading = false;
      });
    }
  }

  private createForm() {
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.required],
      'passwords': this.formBuilder.group({
        first: ['', Validators.required],
        second: ['', Validators.required]
      }, {validator: this.areEqual})
    });
  }
}
