import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccessGuardService {
  static checkSubscriptionActive(user) {
    return user && user.subscription && user.subscription.active;
  }

  constructor() {
  }


}
