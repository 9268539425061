import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { ToasterComponent } from '../component/toaster/toaster.component';

@Injectable()
export class ToasterService {

  errors: Array<string>;

  constructor(private snackBar: MatSnackBar) {
  }

  /**
   * @deprecated since version 2.0, instead use showSuccess()
   */

  public displayMessage(message) {
    this.openToaster(message, 'info');
  }

  public showSuccess(message) {
    this.openToaster(message, 'success');
  }

  public showError(errors?: any): void {
    if (errors) {
      this.setError(errors);
      this.openToaster(this.prepareErrorMessage(), 'error');
    } else {
      this.openToaster('Nieznany błąd', 'error');
    }
  }

  private setError(errors) {
    if (!_.isArray(errors)) {
      this.errors = [errors];
    } else {
      this.errors = errors;
    }
  }

  private prepareErrorMessage(): string {
    let message: string;
    if (_.isArray(this.errors) && this.errors.length) {
      message = this.errors.join('. ');
    } else {
      message = 'Nieznany błąd';
    }

    return message;
  }

  private openToaster(message: string, style?: string) {
    const toasterStyle = style ? `ed-snack-bar-${style}` : undefined;
    this.snackBar.openFromComponent(ToasterComponent, {
      duration: 5000,
      panelClass: toasterStyle,
      data: {
        style: style,
        message: message
      },
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
