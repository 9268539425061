import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialComponentsModule } from '../../../core/material/material-components.module';
import { IndustryCategoryDtoService } from '../../../core/service/industry-category.dto.service';
import { SharedModule } from '../../../shared/shared.module';
import { IndustryCategoryCreateComponent } from './industry-category-create/industry-category-create.component';
import { IndustryCategoryEditComponent } from './industry-category-edit/industry-category-edit.component';

import { IndustryCategoryListComponent } from './industry-category-list/industry-category-list.component';
import { IndustryCategoryComponent } from './industry-category.component';
import { IndustryCategoryFormComponent } from './shared/industry-category-form/industry-category-form.component';

@NgModule({
  declarations: [
    IndustryCategoryListComponent,
    IndustryCategoryCreateComponent,
    IndustryCategoryEditComponent,
    IndustryCategoryFormComponent,
    IndustryCategoryComponent
  ],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    SharedModule,
    RouterModule,
    TranslateModule
  ],
  providers: [
    IndustryCategoryDtoService
  ]
})
export class IndustryCategoryModule {
}
