<div class="sidebar-content-item" [style.width.px]="expandSidebar ? '247' : '64'"
     [class.active-link]="activeLink.isActive">
  <a [routerLink]=[sidebarItem.url]
     routerLinkActive #activeLink="routerLinkActive">
    <span class="sidebar-item-icon" [inlineSVG]="sidebarItem.icon"></span>
    <div class="sidebar-item-name"
         [class.show-item-name]="expandSidebar || isBigScreen">{{(sidebarItem.label | translate)}}</div>
    <span class="sidebar-item-badge" [class.expanded-item-badge]="expandSidebar || isBigScreen"
          *ngIf="sidebarItem.notifyCount">{{sidebarItem.notifyCount}}</span>
  </a>
</div>
