/* tslint:disable:typedef */

import { combineReducers, Reducer } from 'redux';
import { UsersReducer, UsersState } from './main/user/users.reducer';

export * from './main/user/users.reducer';

export interface AppState {
  users: UsersState;
}

const rootReducer: Reducer<AppState> = combineReducers<AppState>({
  users: UsersReducer
});

export default rootReducer;
