import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../core/service/user.service';
import { ToasterService } from '../../shared/utils/toaster.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent {
  public form: FormGroup;
  public isLoading: boolean;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private router: Router) {
    this.createForm();
    this.isLoading = false;
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$')]],
    });
  }

  public changePassword(): void {
    if (this.form.valid) {
      this.isLoading = true;
      this.userService.resetPasswordRequest(this.form.controls['email'].value).subscribe(() => {
        this.toasterService.showSuccess(this.translateService.instant('login.forgotPassword.toasterSuccess'));
        this.router.navigate(['/login']);
        this.isLoading = false;
      }, (err: HttpErrorResponse) => {
        if (err && err.error && err.error.message) {
          this.toasterService.showError([err.error.message]);
        } else {
          this.toasterService.showError([this.translateService.instant('common.toasters.unknownError')]);
        }
        this.isLoading = false;
      });
    }
  }

}
