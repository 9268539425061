import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-admin-toolbar',
  templateUrl: './admin-toolbar.component.html',
  styleUrls: ['./admin-toolbar.component.sass']
})
export class AdminToolbarComponent {
  @Input() toolbarTitle: string;
  @Input() isButtonDisabled: boolean;

  @Output() buttonAction: EventEmitter<string>;

  constructor() {
    this.buttonAction = new EventEmitter();
  }

  public setButtonAction(actionLabel: string): void {
    this.buttonAction.emit(actionLabel);
  }
}
