<form [formGroup]="form">
  <mat-form-field appearance="outline" class="name-field">
    <mat-label>{{ 'admin.industryCategory.form.nameFieldLabel' | translate }}</mat-label>
    <input matInput type="text" placeholder="" [formControl]="form.controls['name']">
    <mat-error>
      <div *ngIf="form.controls['name'].hasError('required')">{{ 'common.forms.errors.required' | translate }}</div>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="color-field">
    <mat-label>{{ 'admin.industryCategory.form.colorFieldLabel' | translate }}</mat-label>
    <input [formControl]="form.controls['color']" matInput type="color" placeholder="">
    <mat-error>
      <div *ngIf="form.controls['color'].hasError('required')">{{ 'common.forms.errors.required' | translate }}</div>
    </mat-error>
  </mat-form-field>
</form>
