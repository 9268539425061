import { Component } from '@angular/core';
import { NavbarService } from '../../../shared/component/navbar/navbar.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.sass']
})
export class ProjectsComponent {
  constructor(private navbarService: NavbarService) {
    this.navbarService.setNavbarTitle('project.title');
  }
}
