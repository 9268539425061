<app-admin-toolbar [isButtonDisabled]="isLoading || !industryCategoryData"
                   [toolbarTitle]="'admin.industryCategory.edit.title'"
                   (buttonAction)="toolbarAction($event)"></app-admin-toolbar>
<div class="admin-create-content">
  <app-industry-category-form *ngIf="!isLoading && industryCategoryData; else showLoader"
                              [editData]="industryCategoryData"></app-industry-category-form>
  <ng-template #showLoader>
    <app-custom-spinner></app-custom-spinner>
  </ng-template>
</div>
