import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialComponentsModule } from '../../core/material/material-components.module';
import { SharedModule } from '../../shared/shared.module';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { AdminService } from './admin.service';
import { CompanyModule } from './company/company.module';
import { IndustryCategoryModule } from './industry-category/industry-category.module';

@NgModule({
  declarations: [
    AdminComponent,
  ],
  imports: [
    SharedModule,
    IndustryCategoryModule,
    CompanyModule,
    CommonModule,
    AdminRoutingModule,
    TranslateModule,
    MaterialComponentsModule
  ],
  providers: [
    AdminService
  ]
})
export class AdminModule {
}
