import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent {
  public displayMessage: string;
  public displayStyle: string;

  constructor(private snackBarRef: MatSnackBarRef<ToasterComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.displayMessage = this.data.message;
    this.displayStyle = this.data.style;
  }

  public close(): void {
    this.snackBarRef.dismiss();
  }
}
