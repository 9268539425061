<div class="login-card">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <div class="login-card-header">
    <div class="login-card-header-logo">
      <div class="login-logo" [inlineSVG]="'logo-dpx-color.svg'"></div>
    </div>
    <div class="login-card-header-title">{{ 'login.forgotPassword.title' | translate }}</div>
  </div>
  <div class="login-card-content">
    <form [formGroup]="form">
      <div class="login-card-subtitle"> {{ 'login.forgotPassword.subtitle' | translate }}</div>
      <div class="my-input-field">
        <mat-form-field floatLabel="never" hideRequiredMarker>
          <mat-label>{{ 'forms.inputsLabel.email' | translate }}</mat-label>
          <input matInput type="email" placeholder=""
                 [formControl]="form.controls['email']">
          <div class="input-prefix-icon" matPrefix [inlineSVG]="'email.svg'"></div>

          <mat-error>
            <div *ngIf="form.controls['email'].hasError('required')">
              {{ 'forms.errors.required' | translate }}
            </div>
            <div *ngIf="form.controls['email'].hasError('pattern')">
              {{ 'forms.errors.emailPattern' | translate }}
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="login-card-buttons-container">
        <button class="my-custom-button" (click)="changePassword()"
                mat-button [disabled]="isLoading">{{ 'forms.buttons.send'| translate }}</button>
        <a [routerLink]="['/login']">{{ 'forms.buttons.back'| translate }}</a>
      </div>
    </form>

  </div>

</div>
