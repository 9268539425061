import { Injectable } from '@angular/core';

@Injectable()
export class CompanyCreateDtoModel {
  public name: string;
  public street: string;
  public streetNo: string;
  public city: string;
  public province: string;
  public user?: number;
  public userEmail: string;
  public isCustomer: boolean;
  public isContractor: boolean;


  constructor(obj?: CompanyCreateDtoModel) {
    this.name = obj && obj.name || null;
    this.street = obj && obj.street || null;
    this.streetNo = obj && obj.streetNo || null;
    this.city = obj && obj.city || null;
    this.province = obj && obj.province || null;
    this.user = obj && obj.user || null;
    this.userEmail = obj && obj.userEmail || null;
    this.isCustomer = obj && obj.isCustomer || false;
    this.isContractor = obj && obj.isContractor || false;
  }
}
