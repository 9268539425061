import { DataSource } from '@angular/cdk/table';
import { DatabaseTable } from './database-table.model';
import { Observable } from 'rxjs';

export class DatabaseTableDataSource<T> extends DataSource<any> {
  constructor(private databaseTable: DatabaseTable<T>) {
    super();
  }

  connect(): Observable<Array<T>> {
    return this.databaseTable.dataChange;
  }

  disconnect() {
  }
}
