import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavbarService {
  private navbarTitle: BehaviorSubject<string>;


  constructor() {
    this.navbarTitle = new BehaviorSubject<string>('');
  }

  public setNavbarTitle(title: string): void {
    this.navbarTitle.next(title);
  }

  public getNavbarTitle(): BehaviorSubject<string> {
    return this.navbarTitle;
  }
}
