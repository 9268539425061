import { Injectable } from '@angular/core';

export interface DataPage<T> {
  items: Array<T>;
  itemsCount: number;
  limit: number;
  page: number;
  sort?: number;
}

@Injectable()
export class DataPageModel<T> implements DataPage<T> {
  public items: Array<T>;
  public itemsCount: number;
  public limit: number;
  public page: number;
  public sort?: number;

  constructor(obj?: any) {
    this.itemsCount = obj && obj.itemsCount || null;
    this.page = obj && obj.page || 1;
    this.limit = obj && obj.limit || 50;
    this.items = obj && obj.items || [];
    this.sort = obj && obj.sort || null;
  }

  public pagesCount(): number {
    return +(this.itemsCount / this.limit).toFixed();
  }
}
