import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ProvniceDtoModel } from '../model/provnice.dto.model';

@Injectable({
  providedIn: 'root'
})
export class ProvinceServiceDto {

  constructor(private http: HttpClient) {
  }

  public getProvincesList(): Observable<Array<ProvniceDtoModel>> {
    return this.http.get(`${environment.apiUrl}/provinces`)
      .pipe(map((response: Array<ProvniceDtoModel>) => response));
  }
}
