<div class="navbar-toolbar">
  <div class="navbar-left">
    <div class="navbar-logo" [inlineSVG]="'logo-dpx.svg'"></div>
    <div class="navbar-separator"></div>
    <div class="navbar-title">{{ navbarTitle | translate }}</div>
  </div>
  <div class="navbar-right">
    <app-search-input (inputValue)="searchInput($event)"></app-search-input>
    <div class="navbar-separator"></div>
    <button class="navbar-user-avatar" mat-icon-button></button>
    <app-user-menu></app-user-menu>
  </div>
</div>
