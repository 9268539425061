import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IndustryCategoryDtoModel } from '../../../../../core/model/industry-category.dto.model';
import { ToasterService } from '../../../../../shared/utils/toaster.service';

@Component({
  selector: 'app-industry-category-form',
  templateUrl: './industry-category-form.component.html',
  styleUrls: ['./industry-category-form.component.sass']
})
export class IndustryCategoryFormComponent {
  private static readonly defaultColor: string = '#000000';

  public form: FormGroup;


  @Input() set editData(value: IndustryCategoryDtoModel) {
    if (value) {
      this.setFormInput(value);
    }
  }

  constructor(private formBuilder: FormBuilder,
              private toasterService: ToasterService,
              private translateService: TranslateService) {
    this.createForm();
  }

  public save(): IndustryCategoryDtoModel {
    if (this.form.valid) {
      return new IndustryCategoryDtoModel({
        name: this.form.controls['name'].value,
        color: this.form.controls['color'].value
      });
    } else {
      this.toasterService.showError([this.translateService.instant('common.forms.errors.formInvalid')]);
      return null;
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      color: [IndustryCategoryFormComponent.defaultColor, Validators.required]
    });
  }

  private setFormInput(industryCategoryData: IndustryCategoryDtoModel): void {
    this.form.controls['name'].setValue(industryCategoryData.name);
    this.form.controls['color'].setValue(industryCategoryData.color);
  }
}
