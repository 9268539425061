import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserMenuItemModel } from './shared/user-menu-item.model';
import { UserMenuService } from './user-menu.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.sass']
})
export class UserMenuComponent implements OnInit {
  public menuItems$: BehaviorSubject<Array<UserMenuItemModel>>;

  constructor(public userMenuService: UserMenuService) {}

  public ngOnInit(): void {
   this.menuItems$ = this.userMenuService.userMenuItems$;
  }

  public menuAction(actionName: string): void {
    this.userMenuService.menuButtonAction(actionName);
  }
}
