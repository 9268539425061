import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PageEvent } from '@angular/material/typings/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { IndustryCategoryDtoModel } from '../../../../core/model/industry-category.dto.model';
import { IndustryCategoryDtoService } from '../../../../core/service/industry-category.dto.service';
import { DataPageRequest, DataPageRequestModel } from '../../../../shared/model/data-page-request.model';
import { DataPageModel } from '../../../../shared/model/data-page.model';
import { PopupHelper } from '../../../../shared/utils/popupHelper';
import { ToasterService } from '../../../../shared/utils/toaster.service';

@Component({
  selector: 'app-industry-category-list',
  templateUrl: './industry-category-list.component.html'
})
export class IndustryCategoryListComponent {
  public industryCategoryDataPage: DataPageModel<IndustryCategoryDtoModel>;
  public isLoading: boolean;
  public rowOnHover: number;
  public rowProcessingId: number;
  public tableColumnsHeader: Array<string>;
  public tableDataSource: MatTableDataSource<IndustryCategoryDtoModel>;

  private industryCategoryRequest: DataPageRequest;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private industryCategoryService: IndustryCategoryDtoService,
              private popupHelper: PopupHelper,
              private route: ActivatedRoute,
              private router: Router,
              private toasterService: ToasterService,
              private translateService: TranslateService) {
    this.industryCategoryRequest = new DataPageRequestModel();
    this.tableColumnsHeader = ['id', 'name', 'color', 'actions'];
    this.isLoading = true;
    this.getIndustryCategoryList();
  }

  public setPage($event: PageEvent): void {
    this.industryCategoryRequest.page = $event.pageIndex + 1;
    this.industryCategoryRequest.limit = $event.pageSize;
    this.getIndustryCategoryList();
  }

  public navigateToAddIndustryCategory(): void {
    this.router.navigate(['../create'], {relativeTo: this.route});
  }

  public navigateToEditIndustryCategory(industryCategoryId: number): void {
    this.router.navigate([`../edit/${industryCategoryId}`], {relativeTo: this.route});
  }

  public deleteIndustryCategory(industryCategoryId: number): void {
    this.rowProcessingId = industryCategoryId;
    this.popupHelper
      .showConfirm(
        `${this.translateService.instant('common.dialogs.confirmTitle')}?`,
        `${this.translateService.instant('common.dialogs.confirmDeleteItem')}?`)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.industryCategoryService.deleteIndustryCategoryAdmin(industryCategoryId)
            .pipe(finalize(() => {
              this.rowProcessingId = null;
            }))
            .subscribe(() => {
              this.getIndustryCategoryList();
            }, (error: HttpErrorResponse) => {
              if (error && error.error && error.error.message) {
                this.toasterService.showError([error.error.message]);
              } else {
                this.toasterService.showError([this.translateService.instant('common.toasters.unknownError')]);
              }
            });
        } else {
          this.rowProcessingId = null;
        }
      });
  }

  private setTableData(industryCategories: Array<IndustryCategoryDtoModel>): void {
    if (industryCategories) {
      if (!this.tableDataSource) {
        this.tableDataSource = new MatTableDataSource<IndustryCategoryDtoModel>(industryCategories);
      } else {
        this.tableDataSource.data = industryCategories;
      }
    }
  }

  private getIndustryCategoryList(): void {
    this.isLoading = true;
    this.industryCategoryService.getIndustryCategoryDataPageAdmin(this.industryCategoryRequest)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((data: DataPageModel<IndustryCategoryDtoModel>) => {
        if (data) {
          this.industryCategoryDataPage = data;
          this.setTableData(data.items);
        }
      }, (error: HttpErrorResponse) => {
        if (error && error.error && error.error.message) {
          this.toasterService.showError([error.error.message]);
        } else {
          this.toasterService.showError([this.translateService.instant('common.toasters.unknownError')]);
        }
      });
  }
}
