import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.sass']
})
export class SearchInputComponent implements OnDestroy {
  public searchInput: FormControl;

  private searchInputSubscription: Subscription;
  private unsubscriber$: Subject<void>;

  @Output() inputValue: EventEmitter<string>;

  constructor() {
    this.unsubscriber$ = new Subject<void>();
    this.inputValue = new EventEmitter<string>();
    this.searchInput = new FormControl();
    this.searchInputSubscription = this.searchInput.valueChanges
      .pipe(debounceTime(800))
      .subscribe((value: string) => {
        this.inputValue.emit(value);
      });
  }

  public ngOnDestroy(): void {
    this.searchInputSubscription.unsubscribe();
  }
}
