import { Injectable } from '@angular/core';


@Injectable()
export class SidebarItemModel {
  public label: string;
  public icon: string;
  public url: string;
  public notifyCount: number;

  constructor(obj?: SidebarItemModel) {
    this.label = obj && obj.label || null;
    this.icon = obj && obj.icon || null;
    this.url = obj && obj.url || null;
    this.notifyCount = obj && obj.notifyCount || 0;
  }
}
