import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialComponentsModule } from '../../../core/material/material-components.module';
import { CompanyDtoService } from '../../../core/service/company.dto.service';
import { SharedModule } from '../../../shared/shared.module';
import { CompanyCreateComponent } from './company-create/company-create.component';
import { CompanyEditComponent } from './company-edit/company-edit.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { CompanyComponent } from './company.component';
import { CompanyFormComponent } from './shared/company-form/company-form.component';

@NgModule({
  declarations: [
    CompanyComponent,
    CompanyCreateComponent,
    CompanyEditComponent,
    CompanyListComponent,
    CompanyFormComponent
  ],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    SharedModule,
    RouterModule,
    TranslateModule
  ],
  providers: [
    CompanyDtoService
  ]
})
export class CompanyModule { }
