export class MiscUtil {
  static goToPath(path: string) {
    const anchor: any = document.createElement('a');
    anchor.href = path;

    const evt: any = document.createEvent('MouseEvents');
    evt.initMouseEvent('click');
    anchor.dispatchEvent(evt);
  }
}
