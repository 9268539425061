<div class="sidebar-container" [class.expanded-sidebar]="expandSidebar">
  <div class="sidebar-content">
    <div class="sidebar-content-logo" [class.expanded-logo]="expandSidebar && !isBigScreen">
      <div class="sidebar-expanded-title" *ngIf="expandSidebar && !isBigScreen">
        <span>{{ 'sidebar.title' | translate }}</span>
      </div>
      <button class="sidebar-toggle" *ngIf="showSidebarToggle" (click)="closeSidebar()" mat-icon-button>
        <span *ngIf="!expandSidebar" [inlineSVG]="'hamburger.svg'"></span>
        <mat-icon *ngIf="expandSidebar">close</mat-icon>
      </button>
      <div class="sidebar-title" *ngIf="!showSidebarToggle">
        <span class="svg-icon" [inlineSVG]="'logo-dpx-color.svg'"></span>
        <span>{{ 'sidebar.title' | translate }}</span>
      </div>
    </div>
    <div class="sidebar-content-items">
      <div *ngFor="let sidebarItemGroup of sidebarItemGroups" class="sidebar-items-group">
        <div class="sidebar-group-label">{{ sidebarItemGroup.label | translate }}</div>
        <app-sidebar-item [sidebarItemData]="sidebarItem"
                          [expandItem]="expandSidebar"
                          [isBigScreen]="isBigScreen"
                          *ngFor="let sidebarItem of sidebarItemGroup.items"></app-sidebar-item>
      </div>
    </div>
  </div>
</div>
