export class ProjectProjectQuestionFileDtoModel {
  public id: number;
  public label: string;
  public fileName: string;

  constructor(data?: any) {
    this.id = data && data.id || null;
    this.label = data && data.label || null;
    this.fileName = data && data.fileName || null;
  }
}
