import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Redux from 'redux';
import { Unsubscribe } from 'redux';
import { BehaviorSubject } from 'rxjs';
import { AppState } from '../../../app.reducer';
import { AppStore } from '../../../app.store';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { UserMenuItemModel } from './shared/user-menu-item.model';
import { UserProfileModel } from '../../../core/model/user-profile.model';
import { getCurrentUser } from '../../../main/user/users.reducer';

@Injectable({
  providedIn: 'root'
})
export class UserMenuService implements OnDestroy {
  public userMenuItems$: BehaviorSubject<Array<UserMenuItemModel>>;

  private storeSubscription: Unsubscribe;

  constructor(@Inject(AppStore) private store: Redux.Store<AppState>,
              private authenticationService: AuthenticationService,
              private translateService: TranslateService,
              private router: Router,
              private route: ActivatedRoute) {
    this.userMenuItems$ = new BehaviorSubject([]);
    this.setUserMenuItems();
    this.storeSubscription = store.subscribe(() => this.updateState());
  }

  public menuButtonAction(actionLabel: string): void {
    switch (actionLabel) {
      case 'LOGOUT':
        this.authenticationService.logout();
        break;
      case 'HEADQUARTER':
        this.router.navigate(['/headquarter'], {relativeTo: this.route});
        break;
    }
  }

  public ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  }

  private updateState(): void {
    const currentUser: UserProfileModel = getCurrentUser(this.store.getState());
    if (currentUser && currentUser.isAdmin()) {
      this.setUserMenuItems(currentUser.isAdmin());
    }
  }

  private setUserMenuItems(isUserAdmin?: boolean): any {
    let userMenuItems: Array<UserMenuItemModel> = [
      {
        onlyAdmin: true,
        label: 'admin.title',
        action: 'HEADQUARTER'
      },
      {
        onlyAdmin: false,
        label: 'login.logOut',
        action: 'LOGOUT'
      }
    ];

    userMenuItems = isUserAdmin ?
      userMenuItems : userMenuItems.filter((item: UserMenuItemModel) => !item.onlyAdmin);
    this.userMenuItems$.next(userMenuItems);
  }
}
