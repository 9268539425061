import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from '../shared/shared.module';
import { MainComponent } from './main.component';
import { InlineSVGModule } from 'ng-inline-svg';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MomentModule,
    InlineSVGModule,
    MomentModule,
    PerfectScrollbarModule
  ],
  entryComponents: [
  ],
  declarations: [
    MainComponent
  ],
  exports: [],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pl',
    }
  ],
  schemas: []
})
export class MainModule {
}
