<div class="popup-header">
   <div class="title-and-close-container">
      <div class="title">
         Zadaj pytanie
      </div>
      <div class="close-button-container" (click)="close()">
         <div class="close-button">Zamknij</div>
      </div>
   </div>
</div>

<mat-dialog-content>
   <div class="popup-content-container">
      <form [formGroup]="form" *ngIf="!isSuccessCreate">
         <mat-form-field>
            <textarea rows="10" matInput placeholder="Treść pytania" [formControl]="form.controls['question']"></textarea>
         </mat-form-field>
      </form>

      <p class="success" *ngIf="isSuccessCreate">Twoje pytanie zostało przesłane do odbiorcy. Zostaniesz poinformowany o odpowiedzi.</p>
   </div>
</mat-dialog-content>

<div class="popup-actions" *ngIf="!isSuccessCreate">
   <button mat-button (click)="close()">Anuluj</button>
   <button mat-button (click)="save()">Wyślij</button>
</div>

<div class="popup-actions" *ngIf="isSuccessCreate">
   <button mat-button (click)="close()">Zamknij</button>
</div>
