<button mat-icon-button class="toaster-close-button" (click)="close()">
   <mat-icon>close</mat-icon>
</button>
<div class="toaster-message-container">
   <div class="toaster-icon">
      <mat-icon *ngIf="displayStyle === 'success'">check_circle_outline</mat-icon>
      <mat-icon *ngIf="displayStyle === 'error'">error_outline</mat-icon>
      <mat-icon *ngIf="displayStyle === 'info'">info</mat-icon>
   </div>
   <p class="toaster-message">{{displayMessage}}</p>
</div>
