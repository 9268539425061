import * as _ from 'lodash';
import { IUser, User } from './user.model';

export interface UserProfile {
  user: IUser;
}

export class UserProfileModel implements UserProfile {
  public user: IUser;


  constructor(obj?: UserProfileModel) {
    this.user = obj && obj.user ? new User(obj.user) : null;
  }

  public isAdmin(): boolean {
    return !!(this.user && this.user.roles && _.includes(this.user.roles, 'ROLE_ADMIN'));
  }
}
