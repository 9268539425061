/**
 * Fibaro HC3
 * API Docs
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ProjectCreateAdminDto } from '../model/projectCreateAdminDto';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath = configuration.basePath || basePath || this.basePath;

    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Update project by Admin
   *
   * @param projectCreateAdminDto update project by admin
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProjectAdmin(projectCreateAdminDto: ProjectCreateAdminDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateProjectAdmin(projectCreateAdminDto: ProjectCreateAdminDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateProjectAdmin(projectCreateAdminDto: ProjectCreateAdminDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateProjectAdmin(projectCreateAdminDto: ProjectCreateAdminDto, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (projectCreateAdminDto === null || projectCreateAdminDto === undefined) {
      throw new Error('Required parameter projectCreateAdminDto was null or undefined when calling updateProjectAdmin.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.configuration.basePath}/admin/projects`,
      projectCreateAdminDto,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
