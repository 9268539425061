import { Action } from 'redux';
import * as UserActions from './user.actions';
import { createSelector } from 'reselect';
import { UserProfileModel } from '../../core/model/user-profile.model';

export interface UsersState {
  currentUser: UserProfileModel;
}

const initialState: UsersState = {
  currentUser: null
};

export const UsersReducer =
  function (state: UsersState = initialState, action: Action): UsersState {
    switch (action.type) {
      case UserActions.SET_CURRENT_USER:
        const user: UserProfileModel = (<UserActions.SetCurrentUserAction>action).user;

        return Object.assign({}, state, {currentUser: user});

      default:
        return state;
    }
  };
export const getUsersState = (state): UsersState => state.users;
export const getCurrentUser = createSelector(
  getUsersState,
  (state: UsersState) => state.currentUser);

