<div class="login-card mat-elevation-z4">
   <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
   <div class="login-card-header">
      <div class="login-card-header-title">Zarejestruj się</div>
   </div>
   <div class="login-card-content">
      <form #form="ngForm" (ngSubmit)="register()">
         <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
            <mat-form-field class="full-width margin-bottom-lg">
               <input matInput placeholder="E-Mail" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" type="email"
                      name="email" [formControl]="registerForm.controls['email']">
               <mat-hint align="start">Tym adresem email będziesz sie logował</mat-hint>
               <mat-error class="margin-bottom-lg">
                  <div *ngIf="registerForm.controls['email'].hasError('required')">Pole wymagane</div>
                  <div *ngIf="registerForm.controls['email'].hasError('pattern')">Podaj poprawny adres email</div>
               </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width margin-bottom-lg">
               <input matInput placeholder="Hasło" minlength="6" type="password" name="password"
                      [formControl]="registerForm.controls['passwords']['controls']['first']">
               <mat-hint align="start">Hasło, musi zawierac minimum 6 znaków</mat-hint>
               <mat-error class="margin-bottom-lg">
                  <div *ngIf="registerForm.controls['passwords']['controls']['first'].hasError('required')">Pole
                     wymagane
                  </div>
                  <div *ngIf="registerForm.controls['passwords']['controls']['first'].hasError('minlength')">Minimum 6
                     znaków
                  </div>
               </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width margin-bottom-lg">
               <input matInput placeholder="Hasło (Potwierdź)" minlength="6" type="password" name="password-confirm"
                      [formControl]="registerForm.controls['passwords']['controls']['second']">
               <mat-hint align="start">Wpisz tutaj jeszcze raz to samo hasło</mat-hint>
               <mat-error class="margin-bottom-lg">
                  <div *ngIf="registerForm.controls['passwords']['controls']['second'].hasError('required')">Pole
                     wymagane
                  </div>
                  <div *ngIf="registerForm.controls['passwords']['controls']['second'].hasError('minlength')">Minimum 6
                     znaków
                  </div>
               </mat-error>
            </mat-form-field>
            <mat-error class="margin-bottom-lg">
               <div *ngIf="registerForm.controls['passwords'].hasError('areEqual')">Hasła nie są takie same</div>
               <div *ngIf="registerForm.controls['passwords'].hasError('required')">Pole wymagane</div>
            </mat-error>
            <div class="extra-options">
               <mat-checkbox class="remember-me">Akceptuję <a href="#">regulamin i postanowienia.</a></mat-checkbox>
            </div>
            <div class="button">
               <button color="primary" mat-raised-button [disabled]="!form.valid || loading">Utwórz konto</button>
            </div>
            <div class="note">Masz już konto? <a [routerLink]="['/login']">Zaloguj się tutaj</a></div>
         </div>
      </form>

   </div>

</div>
