import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdminNavbarItemModel } from '../../core/model/admin-navbar-item.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public adminNavbarItemsList: Array<AdminNavbarItemModel>;

  constructor(private translateService: TranslateService) {
    this.adminNavbarItemsList = [];
    this.setAdminNavbarItems();
  }

  private setAdminNavbarItems(): void {
    this.adminNavbarItemsList = [
      {
        path: 'industry-category',
        label: this.translateService.instant('admin.industryCategory.title')
      },
      {
        path: 'company',
        label: this.translateService.instant('admin.company.title')
      },
    ];
  }

  public get adminNavbarItems(): Array<AdminNavbarItemModel> {
    return this.adminNavbarItemsList;
  }
}
