import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectQuestionDtoService } from '../../../../core/service/project-question.dto.service';

@Component({
  selector: 'app-project-question-create-popup',
  templateUrl: './project-question-create-popup.component.html',
  styleUrls: ['./project-question-create-popup.component.sass']
})
export class ProjectQuestionCreatePopupComponent {
  public form: FormGroup;
  public isSuccessCreate: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ProjectQuestionCreatePopupComponent>,
              private formBuilder: FormBuilder,
              private projectQuestionDtoService: ProjectQuestionDtoService) {
    this.createForm();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.projectQuestionDtoService
      .create(this.data.projectId, this.form.controls['question'].value)
      .subscribe(() => { this.isSuccessCreate = true; });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      question: ['', [Validators.required, Validators.minLength(10)]]
    });
  }
}
