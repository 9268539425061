<div class="login-card mat-elevation-z4">
   <mat-progress-bar mode="indeterminate" class="loading-bar" *ngIf="loading"></mat-progress-bar>
   <div class="login-card-header">
      <div class="login-card-header-title">Ustal nowe hasło</div>
   </div>
   <div class="login-card-content">
      <div class="login-content">
         <div *ngIf="!successMessage">
            <form [formGroup]="registerForm">
               <div class="register-content">
                  <mat-form-field class="full-width margin-bottom-lg">
                     <input matInput placeholder="Hasło (minimum 6 znaków)" minlength="6" type="password"
                            name="password"
                            [formControl]="registerForm.controls['passwords']['controls']['first']">
                     <mat-error class="margin-bottom-lg">
                        <div *ngIf="registerForm.controls['passwords']['controls']['first'].hasError('required')">
                           Pole wymagane
                        </div>
                        <div *ngIf="registerForm.controls['passwords']['controls']['first'].hasError('minlength')">
                           Minimum 6  znaków
                        </div>
                     </mat-error>
                  </mat-form-field>
                  <mat-form-field class="full-width margin-bottom-lg">
                     <input matInput placeholder="Potwierdź hasło" minlength="6" type="password" name="password-confirm"
                            [formControl]="registerForm.controls['passwords']['controls']['second']">
                     <mat-error class="margin-bottom-lg">
                        <div *ngIf="registerForm.controls['passwords']['controls']['second'].hasError('required')">
                           Pole wymagane
                        </div>
                        <div *ngIf="registerForm.controls['passwords']['controls']['second'].hasError('minlength')">
                           Minimum 6 znaków
                        </div>
                     </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="registerForm.controls['passwords'].invalid">
                     <div *ngIf="registerForm.controls['passwords'].hasError('areEqual')">Hasła nie są takie same</div>
                     <div *ngIf="registerForm.controls['passwords'].hasError('required')">Pole wymagane</div>
                  </mat-error>
                  <mat-error *ngIf="errorMessage">
                     <div>{{  errorMessage | stringPrefix: 'resetPassword' | translate }}</div>
                  </mat-error>
                  <div class="button">
                     <button color="primary" class="ed-button ed-button-big mat-flat-button mat-raised-button"
                             mat-raised-button (click)="changePassword()"
                             [disabled]="loading">{{loading ? 'Zapisuję...' : 'Zmień hasło' }}
                     </button>
                  </div>
               </div>
            </form>
         </div>
         <div *ngIf="successMessage">
            <p>
               {{  successMessage | stringPrefix: 'resetPassword' | translate }}
            </p>
         </div>
      </div>

      <div class="login-content">
         <div class="note"><a [routerLink]="['/register']">Załóż konto</a> lub <a [routerLink]="['/login']">Zaloguj
            się</a></div>
      </div>
   </div>
</div>



