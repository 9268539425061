import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { DataPageModel } from '../../shared/model/data-page.model';
import { UserProjectQuestionDtoModel } from '../model/user-project-question.dto.model';

@Injectable()
export class ProjectQuestionDtoService {
  constructor(private http: HttpClient) {

  }

  public create(projectId: number, question: string): Observable<UserProjectQuestionDtoModel> {
    return this.http.post(`${environment.apiUrl}/projects/${projectId}/user-questions`, { question })
      .pipe(map((response: UserProjectQuestionDtoModel) => new UserProjectQuestionDtoModel(response)));
  }

  public getList(projectId: number): Observable<DataPageModel<UserProjectQuestionDtoModel>> {
    return this.http.get(`${environment.apiUrl}/projects/${projectId}/user-questions`)
      .pipe(map((response: any) => {
        const data: DataPageModel<UserProjectQuestionDtoModel> = response as DataPageModel<UserProjectQuestionDtoModel>;
        data.items = _.map(data.items, (item: UserProjectQuestionDtoModel) => new UserProjectQuestionDtoModel(item));
        return data;
      }));
  }
}
