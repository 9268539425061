import { BehaviorSubject } from 'rxjs';

export class DatabaseTable<T> {
  dataChange: BehaviorSubject<Array<T>> = new BehaviorSubject<Array<T>>([]);

  get data(): Array<T> {
    return this.dataChange.value;
  }

  constructor() {

  }
}
