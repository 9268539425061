import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DataPageRequest } from '../../shared/model/data-page-request.model';
import { DataPage, DataPageModel } from '../../shared/model/data-page.model';
import { objectToParams } from '../../shared/utils/object-to-params';
import { IndustryCategoryDtoModel } from '../model/industry-category.dto.model';

@Injectable({
  providedIn: 'root'
})
export class IndustryCategoryDtoService {

  constructor(private http: HttpClient) {
  }

  public getIndustryCategoryById(categoryId: number): Observable<IndustryCategoryDtoModel> {
    return this.http.get(`${environment.apiUrl}/industry-categories/${categoryId}`)
      .pipe(
        map((response: IndustryCategoryDtoModel) => response as IndustryCategoryDtoModel));
  }

  public getIndustryCategoryDataPage(request: DataPageRequest): Observable<DataPage<IndustryCategoryDtoModel>> {
    const filters = objectToParams(request);

    return this.http.get(`${environment.apiUrl}/industry-categories?${filters}`)
      .pipe(
        map((response: DataPage<IndustryCategoryDtoModel>) => (
          new DataPageModel<IndustryCategoryDtoModel>(response) as DataPageModel<IndustryCategoryDtoModel>)));
  }

  public getIndustryCategoryDataPageAdmin(request: DataPageRequest): Observable<DataPage<IndustryCategoryDtoModel>> {
    const filters = objectToParams(request);

    return this.http.get(`${environment.apiUrl}/admin/industry-categories?${filters}`)
      .pipe(
        map((response: DataPage<IndustryCategoryDtoModel>) => (
          new DataPageModel<IndustryCategoryDtoModel>(response) as DataPageModel<IndustryCategoryDtoModel>)));
  }

  public createIndustryCategoryAdmin(request: IndustryCategoryDtoModel): Observable<IndustryCategoryDtoModel> {
    return this.http.post(`${environment.apiUrl}/admin/industry-categories`, request)
      .pipe(
        map((response: IndustryCategoryDtoModel) => response as IndustryCategoryDtoModel));
  }

  public updateIndustryCategoryAdmin(categoryId: number, request: IndustryCategoryDtoModel): Observable<IndustryCategoryDtoModel> {
    return this.http.put(`${environment.apiUrl}/admin/industry-categories/${categoryId}`, request)
      .pipe(
        map((response: IndustryCategoryDtoModel) => response as IndustryCategoryDtoModel));
  }

  public deleteIndustryCategoryAdmin(categoryId: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/admin/industry-categories/${categoryId}`)
      .pipe(
        map((response: any) => response));
  }

}
