export class UserProjectQuestionDtoModel {
  public id: number;
  public question: string;
  public answer: string;

  constructor(data?: UserProjectQuestionDtoModel) {
    this.id = data && data.id || null;

    this.answer = data && data.answer || null;
    this.question = data && data.question || null;
  }
}
